import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/2_upskilling.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/the-farmhill-dalam-program-upskilling-dan-reskilling-guru-bersertifikat";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Farmhill Program in Upskilling and Reskilling Certified Teachers',
    description: 'On August 16th - 22nd August 2022, Farmhill Academy together with BBPPMPV of the Ministry of Education, Culture, Research, and Technology have organized a certified teacher apprenticeship program.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'Farmhill Program in Upskilling and Reskilling Certified Teachers, YFDP, farmhill academy, farmhill, academy, vocational, school, indonesia, agriculture, farming, farming academy, farming school, farming vocational, farming vocational school, farming vocational academy, farming academy in indonesia, farming school in indonesia, farming vocational in indonesia, farming vocational school in indonesia, farming vocational academy in indonesia, farming academy in karanganyar, farming school in karanganyar, farming vocational in karanganyar, farming vocational school in karanganyar, farming vocational academy in karanganyar, farming academy in karanganyar, farming school in karanganyar, farming vocational in karanganyar, farming vocational school in karanganyar, farming vocational academy in karanganyar'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">THE FARMHILL IN CERTIFIED TEACHER UPSKILLING AND RESKILLING PROGRAM</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <p class="blog_text_article" style="text-align: justify;">On August 16th - 22nd August 2022, Farmhill Academy together with BBPPMPV of the Ministry of Education, Culture, Research, and Technology have organized a certified teacher apprenticeship program.
                The Vocational Education Quality Assurance Development Center (BBPPMPV) gives trust to Farmhill Academy in organizing the Certified Teacher Upskilling and Reskilling program. 
                The event was attended by SMK Agriculture teachers from all over Indonesia, with representatives from Nias, North Sumatra and Puncak Jaya, Papua. 
                The event was held on August 16th - 22nd August 2022 at The Farmhill Farm in Karanganyar Regency. It began with remarks from the President Director of The Farmhill, Mr. M. Jatiardi Fitriantoro and BBPPMPV representative Mr. Prima Agung Prihandono, SP., M.Si.
                This program is in line with the vision of Farmhill Academy which encompasses the distribution of modern farming knowledge for SMK Pertanian teachers as insight to be taught to their students.
              </p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
