import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/cocopeat-banner.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/cocopeat-sebagai-solusi-degradasi-lahan-di-indonesia";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Cocopeat as a Solution to Land Degradation in Indonesia',
    description: 'In farming, not only soil can be relied upon as a planting medium. There are other growing media, cocopeat being one of them. Cocopeat is included in the organic hydroponic growing media, because it is made from coconut fiber powder.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'cocopeat for agriculture, advantages of cocopeat, cocopeat, cocopeat as a growing medium, cocopeat as a hydroponic growing medium, cocopeat as an organic growing medium, cocopeat as a growing medium alt'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">COCOPEAT AS A SOLUTION TO LAND DEGRADATION IN INDONESIA</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image} alt="image cocopeat"/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>In farming, not only soil can be relied upon as a planting medium. There are other growing media, cocopeat being one of them. Cocopeat is included in the organic hydroponic growing media, because it is made from coconut fiber powder. Coconut fiber powder is quite easy to find around the house, so it is not uncommon to see this planting method applied in every home. Because Cocopeat is a powder, its existence can be obtained by grinding coconut fiber finely first. One of the benefits of using Cocopeat as a hydroponic growing medium is that it can retain water and has quite a lot of chemical elements.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Cocopeat has the property of absorbing and storing water. It also has pores, which facilitate air exchange, and the entry of sunlight. Its Trichoderma molds content, a type of enzyme from fungi, can reduce disease in the soil. Thus, cocopeat can keep the soil loose and fertile. Although it is touted as an alternative growing medium with a quality as good as soil, it lacks the nutrients found in soil. Therefore, cocopeat requires additional fertilizer as a fertilizer. As an organic growing medium, Cocopeat has several advantages over other growing media. The advantages of Cocopeat include.</p>
           <li>1. Soil-like texture</li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>The shape and texture of Cocopeat resembles soil and its fine grains allow plants to adapt well as if they were planted on soil. The difference between Cocopeat and soil planting media is only in the nutritional content where Cocopeat does not contain nutrients like soil. Therefore, to grow plants with Cocopeat, plants are not only watered with water but also nutrient solutions.</p>
           <li>2. Kelebihan Cocopeat yang dapat menyerap air dengan baik</li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>
           Cocopeat is a growing medium that has a high water absorption capacity and can store more water than what is stored in soil. Cocopeat can store and retain water 10 times better than soil and this is very good of course for plants that grow with a hydroponic system. Because it can retain water well, plant roots do not dry out easily and can be well hydrated.</p>
           <li>3. Environmentally friendly</li>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>Because it is made from organic materials, Cocopeat is very environmentally friendly and can degrade in the soil well if it is no longer used. In addition, Cocopeat can also be recycled back into new planting media of course with certain processes.</p>
              <li>4. More pest resistant</li>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>Some pests such as those from the soil do not like to be in Cocopeat and this can certainly protect the plants better and keep them from being attacked by pests.</p>
              <li>5. Easier for beginners</li>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>Growing plants with Cocopeat is highly recommended for those who are just starting to learn how to grow plants hydroponically. Cocopeat is easy to use when first planting because of its soil-like shape and texture.</p>
         </div>
         </div>
     </div>
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
