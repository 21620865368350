import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/3_alasan.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/alasan-mengapa-farmhill-memilih-sistem-hidroponik-untuk-budiday-melon";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: '4 Alasan Mengapa Farmhill Memilih Sistem Hidroponik Untuk Budidaya Melon',
    description: 'Ada 4 alasan mengapa The Farmhill menggunakan sistem hidroponik untuk budidaya melon. Diantaranya adalah:',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: '4 alasan mengapa farmhill memilih sistem hidroponik untuk budidaya melon, sistem hidroponik melon, sistem hidroponik farmhill, budidaya melon, budidaya melon farmhill, hidroponik farmhill'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">4 ALASAN MENGAPA FARMHILL MEMILIH SISTEM HIDROPONIK UNTUK BUDIDAYA MELON</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>There are 4 reasons why The Farmhill uses a hydroponic system for melon cultivation. Among them are:
             </p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>1). Hydroponics is Suitable for Seasonal Crops</h2>
             
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Annual crops have a faster harvest cycle, and do not require as much space for rooting, as the roots of annual crops are not as numerous.</p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>2). More Uniform Growth</h2>

             <p class="blog_text_article" style={{textAlign: 'justify'}}>The use of a drip irrigation system that provides the same amount of nutrients to each plant, thus making the growth of hydroponic plants uniform.</p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>3). Much Lower Risk of Disease</h2>

             <p class="blog_text_article" style={{textAlign: 'justify'}}>The base medium used in hydroponic systems is neutral, making it easier to control uncontrolled pests.</p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>4). Produces the Best Quality</h2>

             <p class="blog_text_article" style={{textAlign: 'justify'}}>More precise nutrition, more controllable pests, and easier maintenance make it possible to produce the best quality.</p>

         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
