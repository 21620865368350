import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/melon.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/dutch-bucket-system-melon-cultivation";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Budidaya Melon Sistem Dutch Bucket',
    description: 'Sesuai namanya, sistem ini dipopulerkan oleh masyarakat Belanda yang terkenal efisien dalam manajemen air. Sistem Dutch Bucket memanfaatkan wadah yang lebih besar untuk wadah tumbuh tanaman seperti ember. Di dalamnya berisi substrat media kering hydroton, perlite, batu apung atau zeolit. Terdapat juga lubang outlet yang terhubung dengan pipa menuju tandon. Lubang outlet tersebut merupakan media yang dialiri oleh air dan nutrisi air yang dibutuhkan tanaman. Sistem Dutch Bucket merupakan sistem irigasi tertutup (nutrisi & air tersirkulasi kembali). Sistem Dutch Bucket dapat digunakan untuk menanam sayuran buah seperti melon, paprika, cabai, dan timun.Cara kerja Sistem Dutch Bucket dapat dilihat di bawah ini :',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'budidaya melon sistem dutch bucket, melon farmhill, budidaya melon farmhill'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">BUDIDAY MELON SISTEM DUTCH BUCKET</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Sesuai namanya, sistem ini dipopulerkan oleh masyarakat Belanda yang terkenal efisien dalam manajemen air. Sistem Dutch Bucket memanfaatkan wadah yang lebih besar untuk wadah tumbuh tanaman seperti ember. Di dalamnya berisi substrat media kering hydroton, perlite, batu apung atau zeolit. Terdapat juga lubang outlet yang terhubung dengan pipa menuju tandon. Lubang outlet tersebut merupakan media yang dialiri oleh air dan nutrisi air yang dibutuhkan tanaman. Sistem Dutch Bucket merupakan sistem irigasi tertutup (nutrisi & air tersirkulasi kembali). Sistem Dutch Bucket dapat digunakan untuk menanam sayuran buah seperti melon, paprika, cabai, dan timun. Cara kerja Sistem Dutch Bucket dapat dilihat di bawah ini :</p>
           <li>1. Air dari wadah (ember) tandon utama dipompa melalui pipa ke seluruh ember kecil yang digunakan untuk menanam.</li>
           <li>2. Ketika air sampai ke ember kecil tempat menanam tanaman, air dikocorkan atau di semprotkan, bisa juga diteteskan pada setiap tanaman.</li>
           <li>3. Air yang dikocorkan atau disiramkan pada tanaman sebagian akan ditampung oleh ember kecil tempat menanam tanaman.</li>
           <li>4. Daya tampung air pada setiap ember kecil yang dipakai untuk menanam maksimal hanya bisa menampung air sepertiga dari kapasitas ember.</li>
           <li>5. Ketika air melebihi kapasitas maksimal ember kecil, air akan dialirkan kembali ke wadah (ember) tandon utama.</li>
           <li>6. Begitu secara terus menerus sehingga air akan terus berputar dan tidak ada yang terbuang.</li>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
