import React from "react";
import '../css/Footer.css';


const Footer = () => {
    return (
        <footer class="footer-20192"id="contact">
        <div class="site-section">
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <h3 class="footer-title">Quick Links</h3>
                <ul class="list-unstyled links">
                  <li><a href="/en#top">Home</a></li>
                  <li><a href="/en#services">Our Services</a></li>
                  <li><a href="/en#portfolio">Portfolio</a></li>
                  <li><a href="/en#contac">Our Contact</a></li>
                </ul>
              </div>
              <div class="col-sm">
                <h3 class="footer-title">Farmhill Group</h3>
                <ul class="list-unstyled links">
                  <li><a href="/the-farmhill-en">The Farmhill</a></li>
                  <li><a href="/farmhill-academy-en">Farmhill Academy</a></li>
                  <li><a href="/farmhill-technology-en">Farmhill Technology</a></li>
                  <li><a href="/farmhill-land-en">Farmhill Land</a></li>
                </ul>
              </div>
              <div class="col-sm">
                <h3 class="footer-title">Contact Us</h3>
                <ul class="list-unstyled links">
                  <li><a href="https://goo.gl/maps/3QeifeuChskY1jn29">Jalan Cinde Barat No.13, Semarang</a></li>
                  <li><a href="#">admin@thefarmhill.com</a></li>
                </ul>
              </div>
              <div class="col-md-3">
                <h3 class="footer-title">Follow us</h3>
                <ul class="list-unstyled social">
                  <li><a href="https://web.facebook.com/thefarmhill/?_rdc=1&_rdr"><span class="fab fa-facebook-f fw-normal"></span></a></li>
                  <li><a href="https://www.linkedin.com/company/thefarmhill"><span class="fab fa-linkedin-in fw-normal"></span></a></li>
                  <li><a href="https://www.instagram.com/thefarmhill/"><span class="fab fa-instagram fw-normal"></span></a></li>
                  <li><a href="https://www.youtube.com/watch?v=ig8f_d4Q1go"><span class="fab fa-youtube fw-normal"></span></a></li>
                </ul>
              </div>
              
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;