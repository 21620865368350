import HeaderTech from '../components/HeaderEn';
import Footer from '../components/FooterEn';
import tech from '../images/tech.png';
import '../css/Main.css';
import header from '../images/freepik.png';
import indoor from '../images/indoor-tech.jpg';
import icon1 from '../images/greenhouse-tech.png';
import icon2 from '../images/indoorfarming.png';
import icon3 from '../images/smart-farm.png';
import Carousel from 'react-bootstrap/Carousel';
import carousel1 from '../images/container.png';
import carousel2 from '../images/gh1.png';
import carousel3 from '../images/indoor-farming.png';  
import image1 from '../images/in-garden.png';
import image2 from '../images/cocopeat-banner.png';
import image3 from '../images/degradasi-lahan.png';
import image4 from '../images/melon.png';
import image5 from '../images/fakta.png';
import image6 from '../images/tips.png';
import Buttonwa from '../components/ButtonWa';
import DocumentMeta from 'react-document-meta';


function FarmTechEn() {

  const link = () => {  
    var url = "/farmhill-technology";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={tech}/>;
  };


  const meta = {
    title: 'Farmhill Technology',
    description: 'Modern farming practices offer a sustainable and innovative approach to agriculture. By adopting cutting-edge technologies such as precision farming and AI, farmers can improve yields, reduce costs, and minimize environmental impact. Join the modern farming revolution and be part of a more efficient, profitable, and sustainable future for agriculture.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'farmhill, technology, farmhill technology, greenhouse, iot, greenhouse farmhill'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <Buttonwa/>
      <div class="blog_section">
         <div class="container">
            <div class="row">
               <div class="col-md-6 mt-5">
                  <h1 class="blog_taital">Technology for Future Farming</h1>
                  <p class="blog_text">Modern farming practices offer a sustainable and innovative approach to agriculture. By adopting cutting-edge technologies such as precision farming and AI, farmers can improve yields, reduce costs, and minimize environmental impact. Join the modern farming revolution and be part of a more efficient, profitable, and sustainable future for agriculture.</p>
               </div>
               <div class="col-md-6">
                  <div class="blog_img">
                    <img src={header} alt='./images/freepik.png'/>
                    </div>
               </div>
            </div>
         </div>
      </div>

      <div class="about-us section">
         <div class="container">
           <div class="row">
             <div class="col-lg-6">
               <div class="left-image">
                <img src={indoor} alt='./images/indoor-tech.jpg'/>
               </div>
             </div>
             <div class="col-lg-6 align-self-center">
               <div class="section-heading">
                 <h6 style={{fontWeight: "bold"}}>About Us</h6>
                 <h2 style={{fontWeight: "bold"}}>Growing Smarter, Not Harder: Modern Farming Solutions</h2>
               </div>
               <div class="row">
                 <div class="col-lg-4 col-sm-4">
                   <div class="about-item">
                     <h4>25</h4>
                     <h6>Location Greenhouse</h6>
                   </div>
                 </div>
                 <div class="col-lg-4 col-sm-4">
                   <div class="about-item">
                     <h4>3</h4>
                     <h6>Unit Indoor Farming</h6>
                   </div>
                 </div>
                 <div class="col-lg-4 col-sm-4">
                   <div class="about-item">
                     <h4>IOT</h4>
                     <h6>specialist</h6>
                   </div>
                 </div>
               </div>
               <p p class="blog_text">Emphasizes the use of advanced technology and innovative techniques in future farming to increase efficiency and productivity.</p>
             </div>
           </div>
         </div>
       </div>
       
       <div class="services_section layout_padding">
         <div class="container">
            <h1 class="services_taital">What We Do</h1>
            <p class="about_text">Our work for future</p>
            <div class="services_section_2">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon1}/></div>
                     </div>
                     <h4 class="selection_text">Greenhouse and Hydroponic Installation</h4>
                     <p class="ipsum_text">Greenhouse in the form of buildings specifically designed for hydroponic plants with the effectiveness of high land use.</p>  
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon2}/></div>
                     </div>
                     <h4 class="selection_text">Indoor Farming</h4>
                     <p class="ipsum_text">Innovative indoor farming technology, can control environmental conditions with precision and grow fresh and quality plants.</p>        
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon3}/></div>
                     </div>
                     <h4 class="selection_text">AI & IOT Technology</h4>
                     <p class="ipsum_text">Increase the efficiency and effectiveness of land management and planting by using more sophisticated technology.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="portfolio_section" style={{marginTop:'50px'}}>
      <h1 className='text-center'>Our Projects</h1>
      <Carousel id="carousel">
        	
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel2}
          alt="First slide"
        />
        <Carousel.Caption>
          <h1>Greenhouse</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item"> 
        <img
          className="d-block w-100" 
          src={carousel3}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Indoor Farming SMK N Moenadi</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel1}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h3>Container Indoor Farming SMK N 6 Kendal</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>

    <div class="about_section" style={{marginTop:'30px'}}>
         <div class="container">
            <h1 className="article_taital">Our Article</h1>
            <div class="about_section_2">
               <div class="row">
                <div class="col-lg-4">
                  <div className="article_image"><img src={image1}/></div>
                  <h4 class="selection_text">IN GARDEN : CONTAINER INDOOR FARMING</h4>
                  <p class="article_text">Climate change has significant impacts on agriculture. Increased global temperatures, irregular rainfall patterns, droughts, floods and changing seasonal patterns can affect ....</p>
                  <div class="read_bt_article"><a href="/in-garden-container-indoor-farming-en">Read More</a></div>
               </div>
               <div class="col-lg-4">
                <div class="article_image"><img src={image2}/></div>
                <h4 class="selection_text">COCOPEAT AS A SOLUTION TO LAND DEGRADATION IN INDONESIA</h4>
                <p class="article_text">In farming, not only soil can be relied upon as a planting medium. There are other growing media, cocopeat being one of them. Cocopeat is included in the organic hydroponic growing media ....</p>
                <div class="read_bt_article"><a href="/cocopeat-as-a-solution-to-land-degradation-in-indonesia">Read More</a></div>
             </div>
             <div class="col-lg-4">
              <div class="article_image"><img src={image3}/></div>
              <h4 class="selection_text">THE THREAT OF SOIL DEGRADATION TO INDONESIAN AGRICULTURE</h4>
                  <p class="article_text">Land degradation is the process of decreasing land productivity, either temporarily or permanently. Degraded land in other ....</p>
              <div class="read_bt_article"><a href="/the-threat-of-soil-degradation-to-indonesian-agriculture">Read More</a></div>
           </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image4}/></div>
                     <h4 class="selection_text">DUTCH BUCKET SYSTEM MELON CULTIVATION</h4>
                     <p class="article_text">As the name implies, this system was popularized by the Dutch who are known to be efficient in water management. The Dutch Bucket system utilizes a ....</p>
                     <div class="read_bt_article"><a href="/dutch-bucket-system-melon-cultivation">Read More</a></div>
                  </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image5}/></div>
                     <h4 class="selection_text">UNIQUE FACTS ABOUT DRIP IRRIGATION SYSTEM</h4>
                     <p class="article_text">The most widely used system in hydroponic melon cultivation.Drip irrigation systems are divided into 2 types, namely closed systems (dutch bucket) ...</p>
                     <div class="read_bt_article"><a href="/unique-facts-about-drip-irrigation-system">Read More</a></div>

                  </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image6}/></div>
                     <h4 class="selection_text">TIPS FOR SUCCESS IN HYDROPONICS</h4>
                     <p class="article_text">There are several tips for successful hydroponics according to Farmhill Technology, among others: Choose the right type of plant (according to your ...</p>
                     <div class="read_bt_article"><a href="/tips-for-success-in-hydroponics">Read More</a></div>
                  </div>
                  
               </div>
            </div>
         </div>
      </div>
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
