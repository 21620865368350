import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/degradasi-lahan.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/the-threat-of-soil-degradation-to-indonesian-agriculture";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Ancaman Degradasi Tanah Bagi Pertanian Indonesia',
    description: 'Degradasi lahan adalah proses penurunan produktivitas lahan, baik yang sifatnya sementara maupun tetap.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'ancaman degradasi tanah, degradasi tanah, degradasi tanah bagi pertanian indonesia, degradasi tanah pertanian indonesia, degradasi tanah indonesia, degradasi tanah pertanian, degradasi'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">ANCAMAN DEGRADASI TANAH BAGI PERTANIAN INDONESIA</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Degradasi lahan adalah proses penurunan produktivitas lahan, baik yang sifatnya sementara maupun tetap. Lahan terdegradasi dalam definisi lain sering disebut lahan tidak produktif, lahan kritis, atau lahan tidur yang dibiarkan terlantar tidak digarap dan umumnya ditumbuhi semak belukar. Lahan yang telah terdegradasi berat dan menjadi lahan kritis luasnya sekitar 48,3 juta ha atau 25,1% dari luas wilayah Indonesia. Untuk lahan gambut dari sekitar 14,9 juta ha lahan gambut di Indonesia, ± 3,74 juta ha atau 25,1 % dari total luas gambut telah terdegradasi dan ditumbuhi semak belukar. Proses degradasi lahan dimulai dengan tidak terkontrolnya konversi hutan, dan usaha pertambangan kemudian diikuti dengan penggunaan lahan yang tidak sesuai dengan potensi dan pengelolaan lahan yang kurang tepat. Lahan terdegradasi baik di tanah mineral maupun gambut ini menjadi sumber emisi Gas Rumah Kaca (GRK) karena rentan terhadap kebakaran di musim kemarau panjang. Sesuai Perpres No. 61 tahun 2011 dan himbauan dari Kelompok Bank Dunia, bahwa “rehabilitasi lahan terdegradasi/terlantar harus memprioritaskan investasi pada sektor pertanian dan perkebunan kelapa sawit untuk produksi pertanian/perkebunan yang berkelanjutan”, hal tersebut perlu direalisasikan secara nasional. </p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Pada tahun 2022 lalu, berdasarkan artikel yang ditulis di website jatengprov.go.id Gubernur Jawa Tengah Ganjar Pranowo meminta seluruh pihak turun tangan, untuk mengatasi penurunan produktivitas petani bawang merah di Desa Krasak, Kecamatan Brebes, Kabupaten Brebes. Penurunan produktivitas itu dinilai menjadi salah satu penyebab harga bawang merah di pasar tinggi, dan berkontribusi pada inflasi di Jawa Tengah. Penyebab penurunan produksi ini yaitu kerusakan tanah atau degradasi lahan.Degradasi lahan merupakan masalah yang kompleks, namun ada beberapa solusi yang dapat dilakukan untuk mengatasi atau mencegah degradasi lahan, seperti pertanian berkelanjutan, pengelolaan air yang efisien, konservasi tanah, pengelolaan limbah pertanian, reboisasi dan pelestarian hutan, edukasi dan kesadaran petani, dan pengawasan dan regulasi yang ketat.</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
