import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/2_upskilling.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/farmhill-program-in-upskilling-and-reskilling-certified-teachers";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'The Farmhill Dalam Program Upskilling Dan Reskilling Guru Bersertifikat',
    description: 'Pada tanggal 16 - 22 Agustus 2022, Farmhill Academy bersama BBPPMPV Kemendikbud telah menyelenggarakan program magang guru bersertifikat.Pusat Pengembangan Penjaminan Mutu Pendidikan Kejuruan (BBPPMPV) memberikan kepercayaan kepada Farmhill Academy dalam menyelenggarakan program Certified Teacher Upskilling and Reskilling',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'The Farmhill Dalam Program Upskilling Dan Reskilling Guru Bersertifikat, YFDP, farmhill academy, farmhill, academy, vocational, school, indonesia, agriculture, farming, farming academy, farming school, farming vocational, farming vocational school, farming vocational academy, farming academy in indonesia, farming school in indonesia, farming vocational in indonesia, farming vocational school in indonesia, farming vocational academy in indonesia, farming academy in karanganyar, farming school in karanganyar, farming vocational in karanganyar, farming vocational school in karanganyar, farming vocational academy in karanganyar, farming academy in karanganyar, farming school in karanganyar, farming vocational in karanganyar, farming vocational school in karanganyar, farming vocational academy in karanganyar'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">PROGRAM UPSKILLING DAN RESKILLING GURU BERSERTIFIKAT</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <p class="blog_text_article" style="text-align: justify;">
              Pada tanggal 16 - 22 Agustus 2022, Farmhill Academy bersama BBPPMPV Kemendikbud telah menyelenggarakan program magang guru bersertifikat.
              Pusat Pengembangan Penjaminan Mutu Pendidikan Kejuruan (BBPPMPV) memberikan kepercayaan kepada Farmhill Academy dalam menyelenggarakan program Certified Teacher Upskilling and Reskilling
              Acara tersebut dihadiri oleh para guru SMK Pertanian dari seluruh Indonesia, dengan perwakilan dari Nias, Sumatera Utara dan Puncak Jaya, Papua.
              Acara dilaksanakan pada tanggal 16 – 22 Agustus 2022 di The Farmhill Farm Kabupaten Karanganyar. Diawali dengan sambutan dari Direktur Utama The Farmhill Bapak M. Jatiardi Fitriantoro dan perwakilan BBPPMPV Bapak Prima Agung Prihandono, SP., MSi.
              Program ini sejalan dengan visi Farmhill Academy yang mencakup penyebaran pengetahuan pertanian modern bagi para guru SMK Pertanian sebagai wawasan untuk diajarkan kepada siswanya.
            </p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
