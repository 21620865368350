import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/in-garden.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/in-garden-container-indoor-farming";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'In Garden : Container Indoor Farming',
    description: 'Perubahan iklim memiliki dampak signifikan terhadap pertanian. Peningkatan suhu global, pola curah hujan yang tidak teratur, kekeringan, banjir, dan perubahan pola musim dapat mempengaruhi produktivitas tanaman dan hewan, serta keberlanjutan produksi pangan.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'In Garden : Container Indoor Farming, In Garden, Container farming farmhill, container farming, container farming jakarta, container farming indonesia, container farming system, container farming technology, container farming system indonesia, container farming system jakarta, container farming system indonesia, container farming system technology, container farming system technology indonesia, container farming system technology jakarta, container farming system technology'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">IN GARDEN : CONTAINER INDOOR FARMING</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Perubahan iklim memiliki dampak signifikan terhadap pertanian. Peningkatan suhu global, pola curah hujan yang tidak teratur, kekeringan, banjir, dan perubahan pola musim dapat mempengaruhi produktivitas tanaman dan hewan, serta keberlanjutan produksi pangan. Petani harus menghadapi tantangan baru dalam mengelola air, pengendalian hama dan penyakit, serta penyesuaian praktik pertanian untuk menghadapi kondisi iklim yang berubah.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Pertumbuhan populasi global berarti permintaan akan makanan dan produk pertanian juga meningkat. Petani harus mampu memenuhi permintaan ini dengan meningkatkan produktivitas dan efisiensi produksi. Namun, pertumbuhan populasi juga berarti tekanan tambahan pada sumber daya alam, seperti lahan pertanian yang terbatas, air, dan nutrisi tanah. Penting untuk mencari solusi berkelanjutan yang memungkinkan pertanian untuk memenuhi kebutuhan makanan yang meningkat tanpa mengorbankan lingkungan.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>
                Kemajuan teknologi telah memberikan dampak positif terhadap sektor pertanian. Penggunaan sensor, robotika, drone, dan analisis data memungkinkan petani untuk mengumpulkan informasi yang lebih akurat tentang tanaman dan hewan mereka, mengoptimalkan penggunaan sumber daya, dan meningkatkan efisiensi produksi. Teknologi juga dapat membantu dalam pengelolaan irigasi, pemantauan cuaca, pemilihan varietas tanaman yang tahan terhadap iklim, dan pemrosesan hasil pertanian. Namun, akses dan adopsi teknologi yang merata masih menjadi tantangan di beberapa daerah.
             </p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Dalam rangka menghadapi tantangan yang dihadapi sektor pertanian saat ini, penting bagi petani dan pemangku kepentingan lainnya untuk berkolaborasi, berbagi pengetahuan, dan mengadopsi praktik berkelanjutan. Inovasi teknologi yang lebih lanjut, pendekatan adaptif terhadap perubahan iklim, dan kebijakan yang mendukung pertanian berkelanjutan akan menjadi kunci untuk mengatasi tantangan masa depan dalam memenuhi kebutuhan pangan global. dengan adanya situasi yang terjadi, kami menghadirkan In Garden sebagai solusi pertanian berkelanjutan. In Garden memiliki kelebihan sebagai berikut.</p>
           <li>1. Climate & Eco Friendly </li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>Dirancang menggunakan Automatic Environmental Controller dan Growlight System untuk mengontrol kondisi lingkungan khusus bagi tanaman, sehingga sepanjang tahun dapat menghasilkan pasokan makanan berkualitas tanpa terdampak cuaca buruk.</p>
           <li>2. Modular </li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>Container berukuran 320 square feet ini menjadi solusi efektif terhadap lahan non-pertanian. Sifatnya yang modular membuat konektivitas produsen dan konsumen meningkat karena memotong durasi, transportasi, dan limbah hasil pengiriman. </p>
           <li>3. Grow in Box </li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>Container Farming menjadi media bertani yang lengkap mulai dari proses penyemaian hingga panen yang dapat dilakukan langsung di dalam kontainer. Petani maupun non-petani dapat bertani secara efisien karena dapat mengelolanya kapanpun hanya dalam sebuah box container.</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
