import HeaderTech from '../components/Header';
import Footer from '../components/Footer';
import '../css/Main.css';
import DocumentMeta from 'react-document-meta';
import Logo from '../images/fgroup.png';
import Buttonwa from '../components/ButtonWa';
import header from '../images/header.jpeg';
import thefarmhill from '../images/thefarmhill.png';
import academy from '../images/academy.png';
import tech from '../images/tech.png';
import land from '../images/land.png';
import greenhouse from '../images/greenhouse.png';
import vertical from '../images/vertical-farming.png';
import smart from '../images/smart-farming.png';
import farmer from '../images/farmer.png';
import melon from '../images/icon_melon.png';
import lecture from '../images/lecture.png';

import Carousel from 'react-bootstrap/Carousel';
import carousel1 from '../images/enterpreneur-moenadi.jpg';
import carousel2 from '../images/gh-drone.png';
import carousel3 from '../images/magang-guru.jpg';  
import carousel4 from '../images/melon_thefarmhill.png';




function FarmTechId() {

  const link = () => {  
    var url = "/en";
    return window.location.href = url;
  };

  const logo = () => {
    return <img src={Logo}/>;
  };

  const meta = {
    title: 'Farmhill Group',
    description: 'Berawal dari bisnis pertanian hidroponik, kami terus berkembang menjadi pertanian modern nomor satu di Indonesia. Melalui Farmhill Group, kami terus berinovasi dan mengembangkan beberapa fokus area yang dikelompokkan menjadi The Farmhill, Farmhill Academy, Farmhill Technology dan Farmhill Land.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'farmhill tech, farmhill, farmhill technology, farmhill semarang, IOT farmhill, modern farming, modern farming semarang, greenhouse semarang'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
    <div>
      <HeaderTech link={link} logo={logo}/>
      <Buttonwa/>
      <div class="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <div class="left-content header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                <div class="row">
                  <div class="col-lg-4 col-sm-4">
                  </div>
                  <div class="col-lg-12">
                    <h2>Selalu Berinovasi Untuk Pertanian</h2>
                    <p>Berawal dari bisnis pertanian hidroponik, kami terus berkembang menjadi pertanian modern nomor satu di Indonesia. Melalui Farmhill Group, kami terus berinovasi dan mengembangkan beberapa fokus area yang dikelompokkan menjadi The Farmhill, Farmhill Academy, Farmhill Technology dan Farmhill Land.</p>
                  </div>
                  <div class="col-lg-12">
                    <div class="main-green-button scroll-to-section">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="right-image wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                <img src={header} style={{borderRadius:'10%'}} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div id="features" class="features section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="features-content">
            <div class="row">
              <div class="col-lg-3">
                <a href="the-farmhill">
                <div class="features-item first-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
                  <div class="first-number number">
                    <h6>01</h6>
                  </div>
                  <div class="icon"><img src={thefarmhill} alt=""/></div>
                  <h4>The Farmhill</h4>
                  <div class="line-dec"></div>
                  <p>Bergerak dalam bidang penjualan retail hasil bumi khususnya melon hidroponik</p>
                </div>
              </a> 
              </div>
              <div class="col-lg-3">
                <a href="/farmhill-academy">
                <div class="features-item second-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                  <div class="second-number number">
                    <h6>02</h6>
                  </div>
                  <div class="icon"><img src={academy} alt=""/></div>
                  <h4>Farmhill Academy</h4>
                  <div class="line-dec"></div>
                  <p>Bergerak di bidang akademi atau pelatihan seputar pertanian modern untuk semua kalangan</p>
                </div>
              </a>
              </div>
              <div class="col-lg-3">
                <a href="/farmhill-technology">
                <div class="features-item first-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                  <div class="third-number number">
                    <h6>03</h6>
                  </div>
                  <div class="icon"><img src={tech} alt=""/></div>
                  <h4>Farmhill Tech</h4>
                  <div class="line-dec"></div>
                  <p>Bergerak di bidang teknologi pertanian modern yang bertujuan untuk menyelesaikan permasalahan dalam dunia pertanian</p>
                </div>
              </a>
              </div>
              <div class="col-lg-3">
                <a href="/farmhill-land">
                <div class="features-item second-feature last-features-item wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                  <div class="fourth-number number">
                    <h6>04</h6>
                  </div>
                  <div class="icon"><img id="icon" src={land} alt=""/></div>
                  <h4>Farmhill Land</h4>
                  <div class="line-dec"></div>
                  <p>Bergerak di bidang agrowisata yang mendukung konsep wisata pertanian</p>
                </div>
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="services" class="our-services section">
    <div class="container" style={{paddingBottom:'30px'}}>
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="section-heading wow bounceIn" data-wow-duration="1s" data-wow-delay="0.2s">
            <h2>Apa yang Kami <span>Lakukan</span> dan <em>Tawarkan</em> Kepada Klien Kami</h2>
          </div> 
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.3s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon_1">
                  <img src={greenhouse} alt=""/>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Instalasi Greenhouse & Hidroponik</h4>
                  <p>Rumah kaca berupa bangunan yang dirancang khusus untuk tanaman hidroponik dengan efektivitas penggunaan lahan yang tinggi.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.4s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon_1">
                  <img src={vertical} alt=""/>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Indoor Farming</h4>
                  <p>Teknologi pertanian dalam ruangan yang inovatif, dapat mengontrol kondisi lingkungan dengan presisi dan menumbuhkan tanaman yang segar dan berkualitas.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon_1">
                  <img src={smart} alt=""/>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Teknologi AI & IoT</h4>
                  <p>Meningkatkan efisiensi dan efektivitas pengolahan lahan dan penanaman dengan menggunakan teknologi yang lebih canggih.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.6s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon_1">
                  <img src={farmer} alt=""/>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>YFDP & YAIP</h4>
                  <p>Magang untuk guru dan mahasiswa dengan fokus untuk mempromosikan kolaborasi antar bidang untuk industri pertanian modern.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.7s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon_1">
                  <img src={melon} alt=""/>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Premium Melon Producer</h4>
                  <p> Kami adalah produsen melon premium nomor satu di Indonesia </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="service-item wow bounceInUp" data-wow-duration="1s" data-wow-delay="0.8s">
            <div class="row">
              <div class="col-lg-4">
                <div class="icon_1">
                  <img src={lecture} alt=""/>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="right-content">
                  <h4>Pesantren Plus</h4>
                  <p>Ekosistem pendidikan pertanian modern dan kewirausahaan dilakukan di pesantren.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="portfolio_section" id='portfolio' style={{marginTop:'50px', marginBottom:'100px'}}>
  <div class="section-heading portfolio wow fadeInLeft text-center" data-wow-duration="1s" data-wow-delay="0.3s">
          <h6>Portofolio Kami</h6>
          <h2>Kegiatan &<span> Projek</span> yang Kami Kerjakan</h2>
        </div>
        <Carousel id="carousel">
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel1}
          alt="First slide"
        />
        <Carousel.Caption>
        <h3>Kursus Kelas di SMK N H. Moenadi</h3>
          <p>Farmhill Academy</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item"> 
        <img
          className="d-block w-100" 
          src={carousel2}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h1>Greenhouse di Solo</h1>
          <p>Farmhill Technology</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h1>Magang Guru</h1>
          <p>Farmhill Academy</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel4}
          alt="Fourth slide"
        />
        <Carousel.Caption>
          <h1>Melon</h1>
          <p>The Farmhill</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>

  
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechId;
