import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/in-garden.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/in-garden-container-indoor-farming-en";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'In Garden : Container Indoor Farming',
    description: 'In farming, not only soil can be relied upon as a planting medium. There are other growing media, cocopeat being one of them. Cocopeat is included in the organic hydroponic growing media, because it is made from coconut fiber powder.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'In Garden : Container Indoor Farming, In Garden, Container farming farmhill, container farming, container farming jakarta, container farming indonesia, container farming system, container farming technology, container farming system indonesia, container farming system jakarta, container farming system indonesia, container farming system technology, container farming system technology indonesia, container farming system technology jakarta, container farming system technology'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">IN GARDEN : CONTAINER INDOOR FARMING</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
                <p class="blog_text_article" style={{textAlign: 'justify'}}>Climate change has significant impacts on agriculture. Increased global temperatures, irregular rainfall patterns, droughts, floods and changing seasonal patterns can affect crop and animal productivity and the sustainability of food production. Farmers must face new challenges in managing water, pest and disease control, and adjusting farming practices to deal with changing climate conditions.</p>
                <p class="blog_text_article" style={{textAlign: 'justify'}}>The growing global population means that the demand for food and agricultural products is also increasing. Farmers must be able to meet this demand by increasing productivity and production efficiency. However, population growth also means additional pressure on natural resources, such as limited farmland, water, and soil nutrients. It is important to find sustainable solutions that allow agriculture to meet the increasing demand for food without compromising the environment.</p>
                <p class="blog_text_article" style={{textAlign: 'justify'}}> Technological advancements have had a positive impact on the agricultural sector. The use of sensors, robotics, drones, and data analytics allows farmers to gather more accurate information about their crops and animals, optimize resource use, and increase production efficiency. Technology can also help in irrigation management, weather monitoring, selection of climate-resilient crop varieties, and processing of agricultural products. However, equitable access and adoption of technology remains a challenge in some areas.
                </p>
                <p class="blog_text_article" style={{textAlign: 'justify'}}>In order to meet the challenges facing the agriculture sector today, it is important for farmers and other stakeholders to collaborate, share knowledge and adopt sustainable practices. Further technological innovations, adaptive approaches to climate change, and policies that support sustainable agriculture will be key to addressing future challenges in meeting global food needs. With this situation in mind, we present InGarden as a sustainable agriculture solution.</p>
              <li>1. Climate & Eco Friendly </li>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>Designed using Automatic Environmental Controller and Growlight System to control special environmental conditions for plants, so that throughout the year it can produce quality food supply without being affected by bad weather. </p>
              <li>2. Modular </li>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>Container measuring 320 square feet is an effective solution for non-agricultural land. Its modular nature increases the connectivity between producers and consumers by cutting down on delivery time, transportation, and waste. </p>
              <li>3. Grow in Box </li>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>Container Farming is a complete farming medium from seeding to harvesting that can be done directly inside the container. Farmers and non-farmers can farm efficiently because they can manage it anytime in just a box container.</p>
            </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
