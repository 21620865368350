import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import FarmTechId from "./pages_id/FarmTechId"
import FarmTechEn from "./pages_en/FarmTechEn"
import Home from "./pages_id/Home"
import HomeEn from "./pages_en/HomeEn"
import FarmAcad from "./pages_id/FarmAcad"
import FarmAcadEn from "./pages_en/FarmAcadEn"
import FarmLand from "./pages_id/FarmLand"
import FarmLandEn from "./pages_en/FarmLandEn"
import TheFarm from "./pages_id/TheFarm"
import TheFarmEn from "./pages_en/TheFarmEn"
import Degradasi from "./pages_id/articletech/Degradasi"
import DegradasiEn from "./pages_en/articletech/DegradasiEn"
import BudidayaMelon from "./pages_id/articletech/BudidayaMelon"
import BudidayaMelonEn from "./pages_en/articletech/BudidayaMelonEn"
import Cocopeat from "./pages_id/articletech/Cocopeat"
import CocopeatEn from "./pages_en/articletech/CocopeatEn"
import Fakta from "./pages_id/articletech/Fakta"
import FaktaEn from "./pages_en/articletech/FaktaEn"
import InGarden from "./pages_id/articletech/InGarden"
import InGardenEn from "./pages_en/articletech/InGardenEn"
import Tips from "./pages_id/articletech/Tips"
import TipsEn from "./pages_en/articletech/TipsEn"
import NotFound from "./components/NotFound";

import Hidroponik from "./pages_id/articleacad/Hidroponik"
import HidroponikEn from "./pages_en/articleacad/HidroponikEn"
import Drip from "./pages_id/articleacad/Drip" 
import DripEn from "./pages_en/articleacad/DripEn"
import Media from "./pages_id/articleacad/Media"
import MediaEn from "./pages_en/articleacad/MediaEn"
import Matching from "./pages_id/articleacad/Matching"
import MatchingEn from "./pages_en/articleacad/MatchingEn"
import Upskilling from "./pages_id/articleacad/Upskilling"
import UpskillingEn from "./pages_en/articleacad/UpskillingEn"
import Pelatihan from "./pages_id/articleacad/Pelatihan"
import PelatihanEn from "./pages_en/articleacad/PelatihanEn"
import Yaip3 from "./pages_id/articleacad/Yaip3"






function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/en" element={<HomeEn/>} />
        <Route path="/farmhill-technology" element={<FarmTechId/>} />
        <Route path="/farmhill-technology-en" element={<FarmTechEn/>} />
        <Route path="/farmhill-academy" element={<FarmAcad/>} />
        <Route path="/farmhill-academy-en" element={<FarmAcadEn/>} />
        <Route path="/farmhill-land" element={<FarmLand/>} />
        <Route path="/farmhill-land-en" element={<FarmLandEn/>} />
        <Route path="/the-farmhill" element={<TheFarm/>} />
        <Route path="/the-farmhill-en" element={<TheFarmEn/>} />


        {/* article technology */}
        <Route path="/in-garden-container-indoor-farming" element={<InGarden/>} />
        <Route path="/cocopeat-sebagai-solusi-degradasi-lahan-di-indonesia" element={<Cocopeat/>} />
        <Route path="/ancaman-degradasi-tanah-bagi-pertanian-indonesia" element={<Degradasi/>} />
        <Route path="/budidaya-melon-sistem-dutch-bucket" element={<BudidayaMelon/>} />
        <Route path="/fakta-unik-sistem-irigasi-tetes" element={<Fakta/>} />
        <Route path="/tips-sukses-berhidroponik" element={<Tips/>} />
        
        <Route path="/in-garden-container-indoor-farming-en" element={<InGardenEn/>} />
        <Route path="/cocopeat-as-a-solution-to-land-degradation-in-indonesia" element={<CocopeatEn/>} />
        <Route path="/the-threat-of-soil-degradation-to-indonesian-agriculture" element={<DegradasiEn/>} />
        <Route path="/dutch-bucket-system-melon-cultivation" element={<BudidayaMelonEn/>} />
        <Route path="/unique-facts-about-drip-irrigation-system" element={<FaktaEn/>} />
        <Route path="/tips-for-success-in-hydroponics" element={<TipsEn/>} />


        {/* article academy */}
        <Route path="/alasan-mengapa-farmhill-memilih-sistem-hidroponik-untuk-budiday-melon" element={<Hidroponik/>} />
        <Route path="/mari-mengenal-sistem-drip-irrigation-farmhill" element={<Drip/>} />
        <Route path="/media-tanam-yang-sering-digunakan-dalam-hidroponik" element={<Media/>} />
        <Route path="/the-farmhill-dalam-program-matching-fund" element={<Matching/>} />
        <Route path="/the-farmhill-dalam-program-pelatihan-peningkatan-kompetensi-standar-industri-bagi-dosen-politeknik" element={<Pelatihan/>} />
        <Route path="/the-farmhill-dalam-program-upskilling-dan-reskilling-guru-bersertifikat" element={<Upskilling/>} />
        <Route path="/yaip-batch-3" element={<Yaip3/>} />



        <Route path="/farmhill-program-in-upskilling-and-reskilling-certified-teachers" element={<UpskillingEn/>} />
        <Route path="/growing-media-that-are-often-used-in-hydroponics" element={<MediaEn/>} />
        <Route path="/reasons- why-the-farmhill-chose-a-hydroponic-system-for-melon-cultivation" element={<HidroponikEn/>} />
        <Route path="/the-farmhill-drip-irrigation-system" element={<DripEn/>} />
        <Route path="/the-farmhill-in-the-industry-standard-competency-improvement-training-program-for-polytechnic-lecturers" element={<PelatihanEn/>} />
        <Route path="/the-farmhill-in-the-matching-fund-program" element={<MatchingEn/>} />
        <Route path="*" element={<NotFound/>} />


      </Routes>
    </Router>
  );
}

export default App;
