import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/5_dosen.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/the-farmhill-in-the-industry-standard-competency-improvement-training-program-for-polytechnic-lecturers";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'The Farmhill In The Industry Standard Competency Improvement Training Program For Polytechnic Lecturers',
    description: 'On 3rd - 13th October  2022, Farmhill Academy together with BBPPMPV has held Industry Standard Competency Improvement Training for Polytechnic Lecturers.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'training farmhill, farmhill academy training, polytechnic lecturer training, vocational lecturer training, agriculture lecturer training, animal husbandry lecturer training, fisheries lecturer training, food technology lecturer training, agriculture technology lecturer training, animal husbandry technology lecturer training, fisheries technology lecturer training, food technology lecturer training, agriculture technology lecturer training, animal husbandry technology lecturer training, fisheries technology lecturer training, food technology lecturer training, agriculture technology lecturer training, animal husbandry technology lecturer training, fisheries technology lecturer training, food technology lecturer training, agriculture technology lecturer training, animal husbandry technology lecturer training, fisheries technology lecturer training, food technology lecturer training, agriculture technology lecturer training, animal husbandry technology lecturer training, fisheries technology lecturer training, food technology lecturer training'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">THE FARMHILL IN THE INDUSTRY STANDARD COMPETENCY IMPROVEMENT TRAINING PROGRAM FOR POLYTECHNIC LECTURERS</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <p class="blog_text_article" style={{textAlign: 'justify'}}>On 3rd - 13th October  2022, Farmhill Academy together with BBPPMPV has held Industry Standard Competency Improvement Training for Polytechnic Lecturers.</p>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>The Vocational Education Quality Assurance Development Center (BBPPMPV) again gave Farmhill Academy the trust to carry out Industry Standard </p>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>Competency Training, and this time for Polytechnic Lecturers and / or Vocational Education Providing Universities.</p>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>The event which was held on 3rd - 13th October  2022 at The Farmhill Farm was attended by 5 Agriculture lecturers from all over Indonesia. In this program, participants received training in the form of classroom and practical materials, as well as competency and expertise tests.</p>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>The purpose of this program is for participants to achieve specific excellence through increased lecturer competence. In addition, in order to create renewal of knowledge, skills and work attitudes in line with industry developments.</p>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>In addition to getting material on the use of the latest modern farming technology, hands-on experience in the greenhouse, as well as basic competency tests on improving soft skills, and also getting merchandise from The Farmhill.</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
