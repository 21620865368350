import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/5_dosen.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/the-farmhill-in-the-industry-standard-competency-improvement-training-program-for-polytechnic-lecturers";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'The Farmhill Dalam Program Pelatihan Peningkatan Kompetensi Standar Industri Bagi Dosen Politeknik',
    description: 'Pada tanggal 3 - 13 Oktober 2022, Farmhill Academy bersama BBPPMPV telah menyelenggarakan Pelatihan Peningkatan Kompetensi Standar Industri bagi Dosen Politeknik.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'the farmhill dalam program pelatihan peningkatan kompetensi standar industri bagi dosen politeknik, dosen farmhill, pelatihan farmhill, farmhill academy pelatihan, pelatihan dosen politeknik, pelatihan dosen vokasi, pelatihan dosen pertanian, pelatihan dosen peternakan, pelatihan dosen perikanan, pelatihan dosen teknologi pangan, pelatihan dosen teknologi pertanian, pelatihan dosen teknologi peternakan, pelatihan dosen teknologi perikanan, pelatihan dosen teknologi pertanian, pelatihan dosen teknologi peternakan, pelatihan dosen teknologi perikanan, pelatihan dosen teknologi pangan, pelatihan dosen teknologi pertanian, pelatihan dosen teknologi peternakan, pelatihan dosen teknologi perikanan, pelatihan dosen teknologi pangan, pelatihan dosen teknologi pertanian, pelatihan dosen teknologi peternakan, pelatihan dosen teknologi perikanan, pelatihan dosen teknologi pangan, pelatihan dosen teknologi pertanian, pelatihan dosen teknologi peternakan, pelatihan dosen teknologi perikanan, pelatihan dosen teknologi pangan'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">THE FARMHILL DALAM PROGRAM PELATIHAN PENINGKATAN KOMPETENSI STANDAR INDUSTRI BAGI DOSEN POLITEKNIK</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <p class="blog_text_article" style={{textAlign: 'justify'}}>Pada tanggal 3 - 13 Oktober 2022, Farmhill Academy bersama BBPPMPV telah menyelenggarakan Pelatihan Peningkatan Kompetensi Standar Industri bagi Dosen Politeknik.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Balai Pembinaan Penjaminan Mutu Pendidikan Kejuruan (BBPPMPV) kembali memberikan kepercayaan kepada Farmhill Academy untuk melaksanakan Standar Industri </p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Pelatihan Kompetensi, dan kali ini untuk Dosen Politeknik dan/atau Perguruan Tinggi Penyelenggara Pendidikan Vokasi.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Acara yang diselenggarakan pada tanggal 3 - 13 Oktober 2022 di The Farmhill Farm ini dihadiri oleh 5 dosen Pertanian dari seluruh Indonesia. Dalam program ini, peserta mendapatkan pelatihan berupa materi di kelas dan praktikum, serta uji kompetensi dan keahlian.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Tujuan dari program ini adalah agar peserta mencapai keunggulan tertentu melalui peningkatan kompetensi dosen. Selain itu, agar tercipta pembaharuan pengetahuan, keterampilan dan sikap kerja yang sejalan dengan perkembangan industri.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Selain mendapatkan materi penggunaan teknologi pertanian modern terkini, pengalaman langsung di rumah kaca, serta uji kompetensi dasar peningkatan soft skill, dan juga mendapatkan merchandise dari The Farmhill.</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
