import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/1_drip_iri.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/mari-mengenal-sistem-drip-irrigation-farmhill";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'The Farmhill Drip Irrigation System',
    description: 'Drip Irrigation prioritizes meeting the water needs of plants so as to minimize water loss, maximize plant nutrition, more precise water volume and streamline time so as to save labor.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'The Farmhill Drip Irrigation System, drip irrigation farmhill, drip irrigation, farmhill drip irrigation, drip irrigation system'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">LET'S GET TO KNOW THE FARMHILL DRIP IRRIGATION SYSTEM</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <h2 class="blog_text_article" style={{fontWeight: 'bold'}}>Why Drip Irrigation System?</h2>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Drip Irrigation prioritizes meeting the water needs of plants so as to minimize water loss, maximize plant nutrition, more precise water volume and streamline time so as to save labor.</p>

             <h2 class="blog_text_article"  style={{fontWeight: 'bold'}}>How does it work?</h2>
             <p class="blog_text_article"  style={{textAlign: 'justify'}}>By allowing water containing nutrients to drip slowly through a PE hose connected to a drip stick and PCJ, helping to homogenize water pressure which requires several bars of pressure according to the number of planting and drip points to ensure that nutritious water reaches the roots perfectly.</p>

             <h2 class="blog_text_article" style={{fontWeight: 'bold'}}>Apa keuntungannya?</h2>
             <li>- Water saving</li>
              <li>- Efficient absorption of water by plants</li>
              <li>- Effective in reducing nutrient loss</li>
              <li>- Plant control over root diseases is more controllable</li>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
