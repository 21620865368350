import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/6_matching-fund.jpeg';


function FarmTechEn() {

  const link = () => {  
    var url = "/the-farmhill-in-the-matching-fund-program";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'The Farmhill Dalam Program Matching Fund',
    description: 'The Farmhill kembali mendapatkan kepercayaan dari Kemendikbud untuk terlibat dalam program Matching Fund yang bertujuan untuk menciptakan kerjasama strategis dan sinergi antara Dikti dan Industri. The Farmhill sebagai industri/mitra terpercaya memberikan kontribusi natura dengan menjalankan proses pembelajaran berbasis industri bagi siswa SMK N 6 Kendal.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'The Farmhill in The Matching Fund Program, matching fund the farmhill, farmhill academy'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">THE FARMHILL DALAM PROGRAM MATCHING FUND</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <p class="blog_text_article" style={{textAlign: 'justify'}}>The Farmhill kembali mendapatkan kepercayaan dari Kemendikbud untuk terlibat dalam program Matching Fund yang bertujuan untuk menciptakan kerjasama strategis dan sinergi antara Dikti dan Industri. The Farmhill sebagai industri/mitra terpercaya memberikan kontribusi natura dengan menjalankan proses pembelajaran berbasis industri bagi siswa SMK N 6 Kendal.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Dana Pendamping merupakan bentuk dukungan nyata dari Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi (Kemendikbudristek) Republik Indonesia yang bertujuan untuk menciptakan kerjasama strategis dan sinergi antara Perguruan Tinggi dan Industri.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Selain itu, The Farmhill juga menyerahkan bibit dan cocopeat sebagai bentuk bantuan kepada SMK N 6 Kendal yang dilaksanakan pada hari Senin, 10 Oktober 2022. Program ini sangat menarik, karena The Farmhill juga melibatkan siswa peserta Program Magang Agrigator Remaja MSIB (YAIP) untuk menyampaikan materi pembelajaran langsung kepada siswa SMK N 6 Kendal.</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
