import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/degradasi-lahan.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/ancaman-degradasi-tanah-bagi-pertanian-indonesia";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'The Threat of Soil Degradation to Indonesian Agriculture',
    description: 'Land degradation is the process of decreasing land productivity, either temporarily or permanently. Degraded land in other definitions is often called unproductive land, critical land, or idle land that is left uncultivated and generally overgrown with shrubs.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'ancaman degradasi tanah, degradasi tanah, degradasi tanah bagi pertanian indonesia, degradasi tanah pertanian indonesia, degradasi tanah indonesia, degradasi tanah pertanian, degradasi'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">THE THREAT OF SOIL DEGRADATION TO INDONESIAN AGRICULTURE</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Land degradation is the process of decreasing land productivity, either temporarily or permanently. Degraded land in other definitions is often called unproductive land, critical land, or idle land that is left uncultivated and generally overgrown with shrubs. Land that has been severely degraded and become critical land covers around 48.3 million ha or 25.1% of Indonesia's total area. For peatlands, out of approximately 14.9 million ha of peatlands in Indonesia, ± 3.74 million ha or 25.1% of the total peat area has been degraded and overgrown with shrubs. The process of land degradation begins with uncontrolled forest conversion and mining, followed by land use that is not in accordance with the potential and inappropriate land management. Degraded land on both mineral and peat soils is a source of greenhouse gas (GHG) emissions because it is prone to fires in the long dry season. In accordance with Presidential Regulation No. 61 of 2011 and the appeal of the World Bank Group, that "rehabilitation of degraded/abandoned land should prioritize investment in the agricultural sector and oil palm plantations for sustainable agricultural/plantation production", this needs to be realized nationally.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>In 2022, based on an article written on the jatengprov.go.id website, Central Java Governor Ganjar Pranowo asked all parties to intervene, to overcome the decline in shallot farmers' productivity in Krasak Village, Brebes District, Brebes Regency. The decline in productivity is considered to be one of the causes of the high price of shallots in the market, and contributes to inflation in Central Java. Land degradation is a complex problem, but there are several solutions that can be done to overcome or prevent land degradation, such as sustainable agriculture, efficient water management, soil conservation, agricultural waste management, reforestation and forest conservation, farmer education and awareness, and strict supervision and regulation.</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
