import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/4_medai.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/media-tanam-yang-sering-digunakan-dalam-hidroponik";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Growing Media That Are Often Used in Hydroponics',
    description: 'Cocopeat is a hydroponic growing medium made from coconut powder. Cocopeat can easily hold and bind water better than soil. This planting medium is very good for the growth of any plant, because it has a pH between 5.0 and 6.8.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'Growing Media That Are Often Used In Hydroponics, farmhill academy, hydroponics, media the farmhill, farmhill media'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h2 class="events_taital">3 GROWING MEDIA THAT ARE OFTEN USED IN HYDROPONICS</h2>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>Cocopeat</h2>
              
              <p class="blog_text_article" style={{textAlign: 'justify'}}>Cocopeat is a hydroponic growing medium made from coconut powder. Cocopeat can easily hold and bind water better than soil. This planting medium is very good for the growth of any plant, because it has a pH between 5.0 and 6.8.
              </p>

              <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>Rockwool</h2>

              <p class="blog_text_article" style={{textAlign: 'justify'}}>Rockwool is made from a combination of rocks such as basalt, limestone, and coal, which are heated at 1600 degrees Celsius until they melt into lava, in this melted state, the rocks are centrifuged to form fibers. Rockwool has the ability to hold water and oxygen for aeration in large quantities which is needed for root growth and nutrient absorption in hydroponic systems.</p>

              <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>Husk Charcoal</h2>

              <p class="blog_text_article" style={{textAlign: 'justify'}}>Husk charcoal is a planting medium that is sterile from bacteria and fungi, has a neutral pH and good water binding capacity and good aeration. This planting medium comes from the incomplete combustion of rice husks. Rice husk is a by-product of rice milling. The burning of rice husk aims to increase the carbon and nutrient content in it.</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
