import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/4_medai.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/growing-media-that-are-often-used-in-hydroponics";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: '3 Media Tanam Yang Sering Digunakan Dalam Hidroponik',
    description: 'Cocopeat adalah media tanam hidroponik yang terbuat dari bubuk kelapa. Cocopeat dapat dengan mudah menahan dan mengikat air lebih baik daripada tanah. Media tanam ini sangat baik untuk pertumbuhan tanaman apapun, karena memiliki pH antara 5,0 hingga 6,8.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'media tanam yang sering digunakan dalam hidroponik, media tanam hidroponik, farmhill hidroponik'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h2 class="events_taital">3 MEDIA TANAM YANG SERING DIGUNAKAN DALAM HIDROPONIK</h2>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>Cocopeat</h2>
             
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Cocopeat adalah media tanam hidroponik yang terbuat dari bubuk kelapa. Cocopeat dapat dengan mudah menahan dan mengikat air lebih baik daripada tanah. Media tanam ini sangat baik untuk pertumbuhan tanaman apapun, karena memiliki pH antara 5,0 hingga 6,8.
             </p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>Rockwool</h2>

             <p class="blog_text_article" style={{textAlign: 'justify'}}>Rockwool terbuat dari gabungan batuan seperti basalt, limestone, dan batu bara yang dipanaskan pada suhu 1600 derajat Celcius hingga melebur menjadi lava, dalam keadaan meleleh ini batuan disentrifugasi hingga membentuk serat. Rockwool memiliki kemampuan menahan air dan oksigen untuk aerasi dalam jumlah banyak yang sangat dibutuhkan untuk pertumbuhan akar dan penyerapan nutrisi pada sistem hidroponik.</p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>Husk Charcoal</h2>

             <p class="blog_text_article" style={{textAlign: 'justify'}}>Arang sekam merupakan media tanam yang steril dari bakteri dan jamur, memiliki pH netral dan daya ikat air yang baik serta aerasi yang baik. Media tanam ini berasal dari pembakaran sekam padi yang tidak sempurna. Sekam padi merupakan hasil sampingan dari penggilingan padi. Pembakaran sekam padi bertujuan untuk meningkatkan kandungan karbon dan nutrisi di dalamnya.</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
