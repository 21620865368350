import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/tips.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/tips-sukses-berhidroponik";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Hydroponic Success Tips',
    description: 'There are several tips for successful hydroponics according to Farmhill Technology, among others',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'tips sukses berhidroponik, hidroponik farmhill, hidroponik, hidroponik jakarta, hidroponik indonesia, hidroponik sistem, hidroponik teknologi, hidroponik sistem indonesia, hidroponik sistem jakarta, hidroponik sistem indonesia, hidroponik sistem teknologi, hidroponik sistem teknologi indonesia, hidroponik sistem teknologi jakarta, hidroponik sistem teknologi'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">TIPS FOR SUCCESS IN HYDROPONICS</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>There are several tips for successful hydroponics according to Farmhill Technology, among others:</p>
           <li>1.Choose the right type of plant (according to your hobby/business).</li>
           <li>2.Choose the right hydroponic system.</li>
           <li>3.Appropriate temperature, water, nutrients, and pH.</li>
           <li>4.Enough oxygen for the roots.</li>
           <li>5.Enough sunlight duration.</li>
           <li>6.Wholehearted care.</li>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
