import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/melon.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/budidaya-melon-sistem-dutch-bucket";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Dutch Bucket System Melon Cultivation',
    description: 'As the name implies, this system was popularized by the Dutch who are known to be efficient in water management. The Dutch Bucket system utilizes a larger container for plant growth such as a bucket. It contains a dry media substrate of hydroton, perlite, pumice or zeolite. There is also an outlet hole connected to a pipe leading to the reservoir. The outlet hole is a media that is flowed by water and water nutrients needed by plants. The Dutch Bucket system is a closed irrigation system (nutrients & water recirculate). The Dutch Bucket System can be used to grow fruit vegetables such as melons, peppers, chilies, and cucumbers. How the Dutch Bucket System works can be seen below:',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'Dutch Bucket System Melon Cultivation, Farmhill Dutch Bucket, Melon Farmhill'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">DUTCH BUCKET SYSTEM MELON CULTIVATION</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <p class="blog_text_article" style={{textAlign: 'justify'}}>As the name implies, this system was popularized by the Dutch who are known to be efficient in water management. The Dutch Bucket system utilizes a larger container for plant growth such as a bucket. It contains a dry media substrate of hydroton, perlite, pumice or zeolite. There is also an outlet hole connected to a pipe leading to the reservoir. The outlet hole is a media that is flowed by water and water nutrients needed by plants. The Dutch Bucket system is a closed irrigation system (nutrients & water recirculate). The Dutch Bucket System can be used to grow fruit vegetables such as melons, peppers, chilies, and cucumbers. How the Dutch Bucket System works can be seen below:</p>
            <li>1.Water from the main reservoir is pumped through pipes to all the small buckets used for planting.</li>
            <li>2.When the water reaches the small buckets where the plants are planted, the water is poured or sprayed, or it can be dropped on each plant.</li>
            <li>3.The water that is poured or sprinkled on the plants will be partially collected by the small bucket where the plants are planted.</li>
            <li>4.The water capacity of each small bucket used for planting can only hold a maximum of one-third of the bucket's capacity.</li>
            <li>5.When the water exceeds the maximum capacity of the small bucket, the water will be flowed back into the main reservoir container (bucket).</li>
            <li>6.This is done continuously so that the water will continue to rotate and nothing is wasted.</li>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
