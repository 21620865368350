import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/fakta.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/fakta-unik-sistem-irigasi-tetes";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Unique Facts About Drip Irrigation System',
    description: 'Sistem yang paling banyak digunakan dalam budidaya melon hidroponik.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'fakta unik sistem irigasi tetes, sistem irigasi tetes, sistem irigasi tetes terbuka, sistem irigasi tetes'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">UNIQUE FACTS ABOUT DRIP IRRIGATION SYSTEM</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image} alt="image cocopeat"/></a></div>
              </div>
           </div>
           <div class="caption">
           <li>1.The most widely used system in hydroponic melon cultivation.</li>
           <li>2.Drip irrigation systems are divided into 2 types, namely closed systems (dutch bucket) and open systems.</li>
           <li>3.The open drip irrigation system utilizes cocopeat planting media.</li>
           <li>4.With a drip irrigation system, watering and nutrient delivery is very efficient, done by fertigation with a measured dose.</li>
         </div>
         </div>
     </div>
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
