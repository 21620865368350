import '../css/Header.css';
import React from "react";
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useState } from 'react';

const HeaderTech = (props) => {

  const [show, setShow] = useState(false);
const showDropdown = (e)=>{
    setShow(!show);
}
const hideDropdown = e => {
    setShow(false);
}

   return(

  <Navbar collapseOnSelect expand="lg" className="header-area">
    <Container>
      <Navbar.Brand href="#home">
        <a class="logo">
        {props.logo()}
        </a></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav className='main-nav'>
          <Nav.Link className='nav-text' href="/">Beranda</Nav.Link>
          <NavDropdown title="Farmhill Group" href="/#features" id="collasible-nav-dropdown" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
            <NavDropdown.Item href="/the-farmhill">The Farmhill</NavDropdown.Item>
            <NavDropdown.Item href="/farmhill-academy">Farmhill Academy</NavDropdown.Item>
            <NavDropdown.Item href="/farmhill-technology">Farmhill Technology</NavDropdown.Item>
            <NavDropdown.Item href="/farmhill-land">Farmhill Land</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className='nav-text' href="/#services">Layanan</Nav.Link>
          <Nav.Link className='nav-text' href="/#portfolio">Portofolio</Nav.Link>
          <Nav.Link className='nav-text' href="/#contact">Kontak Kami</Nav.Link>
          <NavDropdown title="Bahasa" id="collasible-nav-dropdown-language">
            <NavDropdown.Item href="#">Indonesia</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>props.link()}>Inggris</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
   );
}
export  default HeaderTech;