import HeaderTech from '../components/Header';
import Footer from '../components/Footer';
import land from '../images/land.png';
import '../css/Main.css';
import Buttonwa from '../components/ButtonWa';
import DocumentMeta from 'react-document-meta';
import header from '../images/land_event.jpg';
import icon1 from '../images/one.png';
import icon2 from '../images/two.png';
import icon3 from '../images/three.png';
import background from '../images/bg-land.png';
import Carousel from 'react-bootstrap/Carousel';
import carousel1 from '../images/land1.png';
import carousel2 from '../images/land2.png';
import carousel3 from '../images/land3.jpeg';
import carousel4 from '../images/land4.jpeg';
import carousel5 from '../images/land5.jpeg';

function FarmTechEn() {

  const link = () => {  
    var url = "/farmhill-land-en";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={land}/>;
  };


  const meta = {
    title: 'Farmhill Land',
    description: 'Farmhill Land juga merupakan salah satu dari The Farmhill Group yang merupakan salah satu pengembangan usaha yang bergerak di bidang agrowisata yang mengusung konsep wisata pertanian.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'farmhill land, farmhill dusun semilir, wisata farmhill semarang, wisata dusun semilir semarang'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <Buttonwa/>
      <div class="blog_section layout_padding">
         <div class="container">
            <div class="row">
               <div class="col-md-6">
                  <h1 class="blog_taital">Farmhill Land</h1>
                  <p class="blog_text">Farmhill Land juga merupakan salah satu dari The Farmhill Group yang merupakan salah satu pengembangan usaha yang bergerak di bidang agrowisata yang mengusung konsep wisata pertanian.</p>
               </div>
               
               <div class="col-md-6">
                  <div class="blog_img"><img src={header} style={{paddingBottom: '50px', paddingTop: '50px', borderRadius: '18%'}}/></div>
               </div>
            </div>
         </div>
      </div>

      <div class="services_section" style={{ backgroundImage: `url(${background})` }}>
         <div class="container">
            <h1 class="services_taital">Program yang Kami Kerjakan</h1>
            <div class="services_section_2">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon1}/></div>
                     </div>
                     <h4 class="selection_text">Tourism</h4>
                     <p class="ipsum_text">Pemanfaatan tanah & bangunan untuk sektor pariwisata berbasis pertanian melalui kerjasama dengan pemerintah daerah, instansi dan sekolah.</p>  
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon2}/></div>
                     </div>
                     <h5 class="selection_text">Edu-Agriculture</h5>
                     <p class="ipsum_text">Pemanfaatan sarana budidaya yang dimiliki (Bergas Greenhouse, Teaching Factory SMK) sebagai Edu-Agriculture.</p>        
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon3}/></div>
                     </div>
                     <h4 class="selection_text">Open Farm</h4>
                     <p class="ipsum_text">Konsep Open Farm, pemanfaatan area dan produk, disajikan dalam piring langsung (farm to plate) melalui restoran di mall, pusat perbelanjaan, dan pembukaan tempat baru.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>


      <div class="portfolio_section" id='portfolio' style={{marginTop:'50px', marginBottom:'100px'}}>
  <div class="section-heading portfolio wow fadeInLeft text-center" data-wow-duration="1s" data-wow-delay="0.3s">
  <h1 className='text-center'>Galeri Kami</h1>
        </div>
      <Carousel id="carousel">
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel1}
          alt="First slide"
        />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item"> 
        <img
          className="d-block w-100" 
          src={carousel2}
          alt="Second slide"
        />

        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel3}
          alt="Third slide"
        />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel4}
          alt="Fourth slide"
        />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel5}
          alt="Fourth slide"
        />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
</div>
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
