import '../css/Header.css';
import React from "react";
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useState } from 'react';

const HeaderTechEn = (props) => {

  const [show, setShow] = useState(false);
const showDropdown = (e)=>{
    setShow(!show);
}
const hideDropdown = e => {
    setShow(false);
}


   return(

  <Navbar collapseOnSelect expand="lg" className="header-area">
    <Container>
      <Navbar.Brand href="#home">
        <a class="logo">
        {props.logo()}
        </a></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav className='main-nav'>
          <Nav.Link className='nav-text' href="/en">Home</Nav.Link>
          <NavDropdown title="Farmhill Group" href="/en#features" id="collasible-nav-dropdown" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
            <NavDropdown.Item href="/the-farmhill-en">The Farmhill</NavDropdown.Item>
            <NavDropdown.Item href="/farmhill-academy-en">Farmhill Academy</NavDropdown.Item>
            <NavDropdown.Item href="/farmhill-technology-en">Farmhill Technology</NavDropdown.Item>
            <NavDropdown.Item href="/farmhill-land-en">Farmhill Land</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className='nav-text' href="/en#services">Services</Nav.Link>
          <Nav.Link className='nav-text' href="/en#portfolio">Portfolio</Nav.Link>
          <Nav.Link className='nav-text' href="/en#contact">Our Contacts</Nav.Link>
          <NavDropdown title="Language" id="collasible-nav-dropdown-language">
            <NavDropdown.Item href="#">English</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>props.link()}>Indonesia</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
   );
}
export  default HeaderTechEn;