import '../css/ButtonWa.css';

const ButtonWa = () => {
    return (
        <a href="https://api.whatsapp.com/send?phone=6282240002842&text=Halo%2C%20saya%20berminat%20bekerja%20sama%20dengan%20Farmhill." class="float" target="_blank">
        <i className="fab fa-whatsapp fw-normal my-float"></i>
        </a>
    );
}

export default ButtonWa;