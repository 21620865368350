import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/yaip3.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/yaip-batch-3";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Telah Dibuka YAIP Batch 3',
    description: 'Youth Agrigator Internship Program (YAIP) Batch III kembali hadir untuk seluruh mahasiswa di Indonesia. Melalui program ini kamu berkesempatan untuk mendapatkan ilmu, pengalaman, dan berbagai skill baru dalam dunia kerja profesional khususnya bidang pertanian modern.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'Telah Dibuka YAIP Batch 3, YAIP Batch 3, YAIP Batch 3 Farmhill, YAIP Batch 3 Farmhill Academy, YAIP Batch 3 Farmhill Technology, YAIP Batch 3 Farmhill Land, YAIP Batch 3 Farmhill Academy Semarang, YAIP Batch 3 Farmhill Technology Semarang, YAIP Batch 3 Farmhill Land Semarang, YAIP Batch 3 Farmhill Academy Indonesia, YAIP Batch 3 Farmhill Technology Indonesia, YAIP Batch 3 Farmhill Land Indonesia'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">TELAH DIBUKA YAIP BATCH 3</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <p class="blog_text_article" style={{textAlign: 'center'}}>
            Halo sobat #Melonials
            Kabar gembira untuk semua sobat #Melonials
            </p>
            <p class="blog_text_article" style={{textAlign: 'justify'}}>Youth Agrigator Internship Program (YAIP) Batch III kembali hadir untuk seluruh mahasiswa di Indonesia. Melalui program ini kamu berkesempatan untuk mendapatkan ilmu, pengalaman, dan berbagai skill baru dalam dunia kerja profesional khususnya bidang pertanian modern.Peserta akan mengikuti program magang bersertifikat ini full secara offline (Work From Office) mulai Agustus-Desember dengan berbagai posisi sebagai berikut:</p>

<li>✅ Academy Division</li>

<li>✅ Creative Marketing (Farmhill Academy, Farmhill Technology, Farmhill Land)</li>

<li>✅ Agronomist - Project Maintenance</li>

<li>✅ Business and Product Development Officer (Technology)</li>

<li>✅ Agronomist</li>

<li>✅ Sales and Marketing The Farmhill</li>

<li>✅ Accounting, Finance and Tax Officer</li>

<li>✅ Human Resources and General Affairs Officer</li>

<li>✅ Partnership and B2B Marketing</li>
<p class="blog_text_article" style={{textAlign: 'justify'}}>Periode pendaftaran terbatas hanya sampai 30 Juni 2023</p>
<p class="blog_text_article" style={{textAlign: 'center'}}><a href='https://bit.ly/YAIPBatch3'>https://bit.ly/YAIPBatch3</a></p>
<p class="blog_text_article" style={{textAlign: 'center'}}>SEGERA DAFTARKAN DIRIMU DAN MENJADI BAGIAN DARI YAIP BATCH III</p>


         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
