import HeaderTech from '../components/HeaderEn';
import Footer from '../components/FooterEn';
import land from '../images/thefarmhill.png';
import '../css/Main.css';
import Buttonwa from '../components/ButtonWa';
import DocumentMeta from 'react-document-meta';
import header from '../images/melon_thefarmhill.JPG';


function FarmTechEn() {

  const link = () => {  
    var url = "/the-farmhill";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={land}/>;
  };


  const meta = {
    title: 'The Farmhill',
    description: 'The Farmhill is here to be the best hydroponic product & service provider for Indonesia. We have grown more than 7 different types of melons and vegetable.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'the farmhill, melon farmhill, melon semarang, farmhill semarang'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <Buttonwa/>
      <div class="blog_section layout_padding">
         <div class="container">
            <div class="row">
               <div class="col-md-6">
                  <h1 class="blog_taital">What is The Farmhill ?</h1>
                  <p class="blog_text">The Farmhill is here to be the best hydroponic product & service provider for Indonesia. We have grown more than 7 different types of melons and vegetable.</p>
                  <div class="read_bt" style={{alignItems: 'center'}}><a href="http://www.thefarmhill.com/">Read More</a></div>
               </div>
               <div class="col-md-6">
                  <div class="blog_img"><img src={header} style={{borderRadius: '10%', marginTop: '50px', marginBottom: '150px'}}/></div>
               </div>
            </div>
         </div>
      </div>

      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
