import HeaderTech from '../components/HeaderEn';
import Footer from '../components/FooterEn';
import academy from '../images/academy.png';
import '../css/Main.css';
import image1 from '../images/1_drip_iri.jpg';
import image2 from '../images/2_upskilling.jpg';
import image3 from '../images/3_alasan.jpg';
import image4 from '../images/4_medai.jpg';
import image5 from '../images/5_dosen.jpg';
import image6 from '../images/6_matching-fund.jpeg';
import Buttonwa from '../components/ButtonWa';
import DocumentMeta from 'react-document-meta';
import header from '../images/header-academy.png';
import icon1 from '../images/one.png';
import icon2 from '../images/two.png';
import icon3 from '../images/three.png';
import icon4 from '../images/four.png';
import icon5 from '../images/five.png';
import icon6 from '../images/six.png';
import icon7 from '../images/seven.png';
import icon8 from '../images/eight.png';
import background from '../images/bg-academy.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/farmhill-academy";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={academy}/>;
  };


  const meta = {
    title: 'Farmhill Academy',
    description: 'Platform edukasi yang berkolaborasi dengan para praktisi berpengalaman di bidangnya untuk membudidayakan pertanian hidroponik dengan baik dan benar serta dapat memberikan manfaat bagi masyarakat.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'farmhill academy, pelatihan farmhill, farmhill academy semarang, pelatihan hidroponik semarang'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <Buttonwa/>
      <div class="blog_section layout_padding">
         <div class="container">
            <div class="row">
               <div class="col-md-6">
                  <div class="blog_img"><img src={header}/></div>
               </div>
               <div class="col-md-6">
                  <h1 class="blog_taital">Farmhill Academy</h1>
                  <p class="blog_text">Platform edukasi yang berkolaborasi dengan para praktisi berpengalaman di bidangnya untuk membudidayakan pertanian hidroponik dengan baik dan benar serta dapat memberikan manfaat bagi masyarakat.</p>
               </div>
            </div>
         </div>
      </div>

      <div class="services_section" style={{ backgroundImage: `url(${background})` }}>
         <div class="container">
            <h1 class="services_taital">Program yang Kami Kerjakan</h1>
            <div class="services_section_2">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon1}/></div>
                     </div>
                     <h4 class="selection_text">Youth Farmers Development Program (YFDP)</h4>
                     <p class="ipsum_text">Merupakan bekal hard skill dan soft skill yang dibutuhkan dalam dunia kerja industri pertanian modern.</p>  
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon2}/></div>
                     </div>
                     <h5 class="selection_text">Youth Agrigator Internship Program (YAIP)</h5>
                     <p class="ipsum_text">Merupakan magang perguruan tinggi dengan fokus untuk mempromosikan kolaborasi lintas sektoral untuk industri pertanian modern</p>        
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon3}/></div>
                     </div>
                     <h4 class="selection_text">Teaching Factory</h4>
                     <p class="ipsum_text">Pengembangan teaching factory inilah yang memungkinkan mitra SMK memiliki kemampuan produksi seperti industri.</p>
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon4}/></div>
                     </div>
                     <h4 class="selection_text">Podcast</h4>
                     <p class="ipsum_text">Merupakan pendidikan pertanian bagi masyarakat luas yang dikemas dalam wadah kekinian.</p>
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon5}/></div>
                     </div>
                     <h4 class="selection_text">Webinar</h4>
                     <p class="ipsum_text">Merupakan edukasi pertanian modern setiap bulan melalui platform online.</p>
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon6}/></div>
                     </div>
                     <h4 class="selection_text">Pesantren Plus</h4>
                     <p class="ipsum_text">Merupakan ekosistem Pendidikan Pertanian Modern dan Kewirausahaan di Pesantren.</p>
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon7}/></div>
                     </div>
                     <h4 class="selection_text">Masa Persiapan Pensiun</h4>
                     <p class="ipsum_text">Merupakan pelatihan pertanian modern skala rumah tangga dan industri untuk mempersiapkan masa pensiun.</p>
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon8}/></div>
                     </div>
                     <h4 class="selection_text">Magang Guru/Dosen</h4>
                     <p class="ipsum_text">Merupakan pendidikan pertanian modern kelas industri untuk para pendidik.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>


		

    <div class="about_section" style={{marginTop:'30px'}}>
         <div class="container">
            <h1 className="article_taital">Artikel Kami</h1>
            <div class="about_section_2">
               <div class="row">
                <div class="col-lg-4">
                  <div className="article_image"><img src={image1}/></div>
                  <h4 class="selection_text">YUK MENGENAL DRIP IRRIGATION SYSTEM THE FARMHILL</h4>
                     <p class="article_text">Drip Irrigation mengutamakan pemenuhan kebutuhan air bagi tanaman sehingga dapat meminimalkan kehilangan air ...</p>
                     <div class="read_bt_article"><a href="/mari-mengenal-sistem-drip-irrigation-farmhill">Selengkapnya</a></div>
               </div>
               <div class="col-lg-4">
                <div class="article_image"><img src={image2}/></div>
                <h4 class="selection_text">PROGRAM UP SKILLING DAN RESKILLING GURU BERSERTIFIKAT</h4>
                     <p class="article_text">Pada 16 Agustus - 22 Agustus 2022, Farmhill Academy bersama BBPPMPV Kemendikbud telah menyelenggarakan ...</p>
                     <div class="read_bt_article"><a href="/the-farmhill-dalam-program-upskilling-dan-reskilling-guru-bersertifikat/">Selengkapnya</a></div>
             </div>
             <div class="col-lg-4">
              <div class="article_image"><img src={image3}/></div>
              <h4 class="selection_text">4 ALASAN MEMILIH HIDROPONIK UNTUK BUDIDAYA MELON</h4>
                     <p class="article_text">Ada 4 alasan kenapa The Farmhill menerapkan sistem hidroponik dalam melakukan budidaya melon ...</p>
                     <div class="read_bt_article"><a href="/alasan-mengapa-farmhill-memilih-sistem-hidroponik-untuk-budiday-melon">Selengkapnya</a></div>
           </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image4}/></div>
                     <h4 class="selection_text">3 MEDIA TANAM YANG SERING DIGUNAKAN DALAM HIDROPONIK</h4>
                     <p class="article_text">Cocopeat merupakan media tanam hidroponik yang terbuat dari serbuk kelapa. Cocopeat dapat mudah ...</p>
                     <div class="read_bt_article"><a href="/media-tanam-yang-sering-digunakan-dalam-hidroponik/">Selengkapnya</a></div>
                  </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image5}/></div>
                     <h4 class="selection_text">PROGRAM PELATIHAN PENINGKATAN KOMPETENSI BERSTANDAR INDUSTRI BAGI DOSEN POLTEK</h4>
                     <p class="article_text">Pada tanggal 3 - 13 Oktober 2022, Farmhill Academy bersama BBPPMPV telah menyelenggarakan Pelatihan ...</p>
                     <div class="read_bt_article"><a href="/the-farmhill-dalam-program-pelatihan-peningkatan-kompetensi-standar-industri-bagi-dosen-politeknik/">Selengkapnya</a></div>

                  </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image6}/></div>
                     <h4 class="selection_text">THE FARMHILL DALAM PROGRAM MATCHING FUND</h4>
                     <p class="article_text">The Farmhill kembali mendapatkan kepercayaan dari Kemendikbud untuk terlibat dalam program Matching ...</p>
                     <div class="read_bt_article"><a href="/the-farmhill-dalam-program-matching-fund/">Selengkapnya</a></div>
                  </div>
                  

  
 
 

               </div>
            </div>
         </div>
      </div>
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
