import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/cocopeat-banner.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/cocopeat-as-a-solution-to-land-degradation-in-indonesia";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Cocopeat Sebagai Solusi Degradasi Lahan di Indonesia',
    description: 'Dalam bercocok tanam, tak hanya tanah yang bisa diandalkan sebagai media tanam. Masih ada media tanam lain, cocopeat salah satunya. Cocopeat termasuk ke dalam media tanam hidroponik yang bersifat organik, karena terbuat dari serbuk serabut kelapa. Serbuk serabut kelapa cukup mudah ditemukan di sekitar rumah, jadi tidak jarang kita melihat metode tanam ini diterapkan di tiap-tiap rumah. Karena Cocopeat adalah serbuk, maka keberadaannya dapat diperoleh menggunakan cara sabut kelapa digiling halus terlebih dahulu. Salah satu manfaat jika menggunakan Cocopeat sebagai media tanam hidroponik ialah dapat menahan air serta memiliki unsur kimia lumayan banyak.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'pertanian cocopeat, kelebihan cocopeat, cocopeat, cocopeat sebagai media tanam, cocopeat sebagai media tanam hidroponik, cocopeat sebagai media tanam organik, cocopeat sebagai media tanam alt'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">COCOPEAT SEBAGAI SOLUSI DEGRADASI LAHAN DI INDONESIA</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image} alt="image cocopeat"/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Dalam bercocok tanam, tak hanya tanah yang bisa diandalkan sebagai media tanam. Masih ada media tanam lain, cocopeat salah satunya. Cocopeat termasuk ke dalam media tanam hidroponik yang bersifat organik, karena terbuat dari serbuk serabut kelapa. Serbuk serabut kelapa cukup mudah ditemukan di sekitar rumah, jadi tidak jarang kita melihat metode tanam ini diterapkan di tiap-tiap rumah. Karena Cocopeat adalah serbuk, maka keberadaannya dapat diperoleh menggunakan cara sabut kelapa digiling halus terlebih dahulu. Salah satu manfaat jika menggunakan Cocopeat sebagai media tanam hidroponik ialah dapat menahan air serta memiliki unsur kimia lumayan banyak.</p>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Cocopeat memiliki sifat mudah menyerap dan menyimpan air. Ia juga memiliki pori-pori, yang memudahkan pertukaran udara, dan masuknya sinar matahari. Kandungan Trichoderma molds-nya, sejenis enzim dari jamur, dapat mengurangi penyakit dalam tanah. Dengan demikian, cocopeat dapat menjaga tanah tetap gembur dan subur. Meski disebut-sebut sebagai media tanam alternatif berkualitas sebaik tanah, namun unsur hara yang ada di tanah, tidak ada padanya. Oleh karena itu, cocopeat memerlukan tambahan pupuk sebagai penyubur. Sebagai media tanam organik, Cocopeat memiliki beberapa kelebihan atau keunggulan dibandingkan media tanam lainnya. Kelebihan Cocopeat tersebut antara lain :</p>
           <li>1. Teksturnya mirip tanah</li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>Bentuk dan tekstur Cocopeat menyerupai tanah dan butirannya yang halus membuat tanaman dapat beradaptasi dengan baik seperti halnya jika ditanam pada tanah. Perbedaan Cocopeat dengan media tanam tanah hanya pada kandungan nutrisinya dimana Cocopeat tidak mengandung unsure hara seperti tanah. Oleh sebab itu  untuk menanam tumbuhan dengan Cocopeat, tanaman tidak hanya disiram air melainkan juga larutan nutrisi.</p>
           <li>2. Kelebihan Cocopeat yang dapat menyerap air dengan baik</li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>
            Cocopeat merupakan media tanam yang memiliki daya serap air yang cukup tinggi dan dapat menyimpan air dalam jumlah yang lebih banyak daripada yang ditampung dalam tanah. Cocopeat dapat menyimpan dan mempertahankan air 10 kali lebih baik dari tanah dan hal ini sangat baik tentunya bagi tanaman yang tumbuh dengan sistem hidroponik. Karena dapat menjaga air dengan baik, akar tanaman tidak mudah kering dan dapat terhidrasi dengan baik.</p>
           <li>3. Ramah lingkungan</li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>Karena terbuat dari bahan organik, Cocopeat sangat ramah lingkungan dan dapat terdegradasi dalam tanah dengan baik jika sudah tidak digunakan. Selain itu Cocopeat juga dapat didaur ulang kembali menjadi media tanam baru tentunya dengan beberapa proses tertentu.</p>
           <li>4. Lebih tahan hama</li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>Beberapa jenis hama seperti hama yang berasal dari tanah tidak suka berada dalam Cocopeat dan hal ini tentunya bisa melindungi tanaman dengan lebih baik dan menjaganya dari serangan hama.</p>
           <li>5. Lebih mudah untuk pemula</li>
           <p class="blog_text_article" style={{textAlign: 'justify'}}>Menanam tanaman dengan Cocopeat sangat dianjurkan bagi mereka yang baru mulai belajar menanam tanaman secara hidroponik. Cocopeat  mudah digunakan saat pertama kali menanam karena bentuk dan teksturnya seperti tanah.</p>
         </div>
         </div>
     </div>
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
