import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/3_alasan.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/reasons- why-the-farmhill-chose-a-hydroponic-system-for-melon-cultivation";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: '4 Alasan Mengapa Farmhill Memilih Sistem Hidroponik Untuk Budidaya Melon',
    description: 'Ada 4 alasan mengapa The Farmhill menggunakan sistem hidroponik untuk budidaya melon. Diantaranya adalah:',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: '4 alasan mengapa farmhill memilih sistem hidroponik untuk budidaya melon, sistem hidroponik melon, sistem hidroponik farmhill, budidaya melon, budidaya melon farmhill, hidroponik farmhill'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">4 ALASAN MENGAPA FARMHILL MEMILIH SISTEM HIDROPONIK UNTUK BUDIDAYA MELON</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Ada 4 alasan mengapa The Farmhill menggunakan sistem hidroponik untuk budidaya melon. Diantaranya adalah:
             </p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>1). Hidroponik Cocok untuk Tanaman Semusim</h2>
             
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Tanaman tahunan memiliki siklus panen yang lebih cepat, dan tidak membutuhkan banyak ruang untuk perakaran, karena akar tanaman tahunan tidak banyak.
             </p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>2). Pertumbuhan Lebih Seragam</h2>

             <p class="blog_text_article" style={{textAlign: 'justify'}}>Penggunaan sistem irigasi tetes yang memberikan jumlah nutrisi yang sama pada setiap tanaman, sehingga membuat pertumbuhan tanaman hidroponik menjadi seragam.</p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>3). Risiko Penyakit Jauh Lebih Rendah</h2>

             <p class="blog_text_article" style={{textAlign: 'justify'}}>Media dasar yang digunakan dalam sistem hidroponik bersifat netral, sehingga memudahkan pengendalian hama yang tidak terkendali.</p>

             <h2 class="blog_text_article" style={{fontWeight:'bold', textAlign: 'left'}}>4). Menghasilkan Kualitas Terbaik</h2>

             <p class="blog_text_article" style={{textAlign: 'justify'}}>Nutrisi yang lebih tepat, hama yang lebih terkendali, dan perawatan yang lebih mudah memungkinkan untuk menghasilkan kualitas terbaik.</p>

         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
