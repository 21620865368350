import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/fakta.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/unique-facts-about-drip-irrigation-system";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Fakta Unik Sistem Irigasi Tetes',
    description: 'Sistem yang paling banyak digunakan dalam budidaya melon hidroponik.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'fakta unik sistem irigasi tetes, sistem irigasi tetes, sistem irigasi tetes terbuka, sistem irigasi tetes'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">FAKTA UNIK SISTEM IRIGASI TETES</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image} alt="image cocopeat"/></a></div>
              </div>
           </div>
           <div class="caption">
           <li>1. Sistem yang paling banyak digunakan dalam budidaya melon hidroponik.</li>
           <li>2. Sistem irigasi tetes dibagi menjadi 2 jenis yaitu sistem tertutup (dutch bucket) dan sistem terbuka.</li>
           <li>3. Pada sistem irigasi tetes terbuka memanfaatkan media tanam cocopeat.</li>
           <li>4. Dengan sistem irigasi tetes, penyiraman dan pemberian nutrisi sangat efisien, dilakukan secara fertigasi dengan dosis terukur.</li>
         </div>
         </div>
     </div>
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
