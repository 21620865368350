import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/1_drip_iri.jpg';


function FarmTechEn() {

  const link = () => {  
    var url = "/the-farmhill-drip-irrigation-system";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Mari Mengenal Sistem Drip Irrigation Farmhill',
    description: 'Irigasi Tetes mengutamakan pemenuhan kebutuhan air tanaman sehingga meminimalkan kehilangan air, memaksimalkan nutrisi tanaman, volume air lebih tepat dan mengefektifkan waktu sehingga menghemat tenaga kerja.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'Mari Mengenal Sistem Drip Irrigation Farmhill, mengenal drip irrigation farmhill, drip irrigation, farmhill drip irrigation, sistem drip irrigation'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">MARI MENGENAL SISTEM DRIP IRRIGATION FARMHILL</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <h2 class="blog_text_article" style={{fontWeight: 'bold'}}>Mengapa Sistem Irigasi Tetes?</h2>
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Irigasi Tetes mengutamakan pemenuhan kebutuhan air tanaman sehingga meminimalkan kehilangan air, memaksimalkan nutrisi tanaman, volume air lebih tepat dan mengefektifkan waktu sehingga menghemat tenaga kerja.</p>

             <h2 class="blog_text_article"  style={{fontWeight: 'bold'}}>Bagaimana cara kerjanya?</h2>
             <p class="blog_text_article"  style={{textAlign: 'justify'}}>Dengan membiarkan air yang mengandung unsur hara menetes perlahan melalui selang PE yang terhubung dengan drip stick dan PCJ, membantu menyeragamkan tekanan air yang membutuhkan tekanan beberapa bar sesuai dengan jumlah tanam dan titik tetes untuk memastikan air yang bernutrisi mencapai akar dengan sempurna.</p>

             <h2 class="blog_text_article" style={{fontWeight: 'bold'}}>Apa keuntungannya?</h2>
             <li>- Hemat air</li>
             <li>- Penyerapan air yang efisien oleh tanaman</li>
             <li>- Efektif dalam mengurangi kehilangan nutrisi</li>
             <li>- Pengendalian tanaman terhadap penyakit akar lebih dapat dikendalikan</li>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
