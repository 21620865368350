import HeaderTech from '../../components/Header';
import Footer from '../../components/Footer';
import iconlogo from '../../images/tech.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/tips.png';


function FarmTechEn() {

  const link = () => {  
    var url = "/tips-for-success-in-hydroponics";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'Tips Sukses Berhidroponik',
    description: 'Ada beberapa tips sukses berhidroponik menurut Farmhill Technology antara lain :',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'tips sukses berhidroponik, hidroponik farmhill, hidroponik, hidroponik jakarta, hidroponik indonesia, hidroponik sistem, hidroponik teknologi, hidroponik sistem indonesia, hidroponik sistem jakarta, hidroponik sistem indonesia, hidroponik sistem teknologi, hidroponik sistem teknologi indonesia, hidroponik sistem teknologi jakarta, hidroponik sistem teknologi'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
           <h1 class="events_taital">TIPS SUKSES BERHIDROPONIK</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
             <p class="blog_text_article" style={{textAlign: 'justify'}}>Ada beberapa tips sukses berhidroponik menurut Farmhill Technology antara lain :</p>
           <li>1. Pilihlah jenis tanaman yang tepat (sesuai hobi/bisnis).</li>
           <li>2. Pilih sistem hidroponik yang tepat.</li>
           <li>3. Suhu, air, nutrisi, dan pH yang sesuai.</li>
           <li>4. Oksigen yang cukup bagi akar.</li>
           <li>5. Durasi sinar matahari yang cukup.</li>
           <li>6. Perawatan sepenuh hati.</li>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
