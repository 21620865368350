import HeaderTech from '../../components/HeaderEn';
import Footer from '../../components/FooterEn';
import iconlogo from '../../images/academy.png';
import '../../css/Main.css';
import DocumentMeta from 'react-document-meta';
import image from '../../images/6_matching-fund.jpeg';


function FarmTechEn() {

  const link = () => {  
    var url = "/the-farmhill-dalam-program-matching-fund";

    return window.location.href = url;
  };

  const logo = () => {
    return <img src={iconlogo}/>;
  };


  const meta = {
    title: 'The Farmhill in The Matching Fund Program',
    description: 'The Farmhill again gained the trust of the Ministry of Education and Culture to be involved in the Matching Fund program which aims to create strategic cooperation and synergy between Higher Education and Industry. The Farmhill as a trusted industry/partner contributes in kind by running an industry-based learning process for SMK N 6 Kendal students.',
    meta: {
      charset: 'utf-8',
      name: {
         keywords: 'The Farmhill in The Matching Fund Program, matching fund the farmhill, farmhill academy'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div>
      <HeaderTech link={link} logo={logo}/>
      <div class="events_section layout_padding" style={{paddingBottom:'50px'}}>
        <div class="container" style={{paddingTop: "100px"}}>
        <h1 class="events_taital">THE FARMHILL IN THE MATCHING FUND PROGRAM</h1>
           <div class="events_section_2">
              <div class="events_bg">
                 <div class="play_icon"><a href="#"><img src={image}/></a></div>
              </div>
           </div>
           <div class="caption">
           <p class="blog_text_article" style={{textAlign: 'justify'}}>The Farmhill again gained the trust of the Ministry of Education and Culture to be involved in the Matching Fund program which aims to create strategic cooperation and synergy between Higher Education and Industry. The Farmhill as a trusted industry/partner contributes in kind by running an industry-based learning process for SMK N 6 Kendal students.</p>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>The Matching Fund is a tangible form of support from the Ministry of Education, Culture, Research and Technology (Kemendikbudristek) of the Republic of Indonesia which aims to create strategic cooperation and synergy between Higher Education and Industry.</p>
              <p class="blog_text_article" style={{textAlign: 'justify'}}>In addition, The Farmhill also handed over seeds and cocopeat as a form of assistance to SMK N 6 Kendal which was held on Monday, 10th October 2022. This program is very interesting, because The Farmhill also involves students participating in MSIB's Youth Agrigator Internship Program (YAIP) to deliver learning materials directly to SMK N 6 Kendal students</p>
         </div>
         </div>
     </div>


      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechEn;
