import HeaderTech from '../components/Header';
import Footer from '../components/Footer';
import '../css/Main.css';
import header from '../images/freepik.png';
import indoor from '../images/indoor-tech.jpg';
import icon1 from '../images/greenhouse-tech.png';
import icon2 from '../images/indoorfarming.png';
import icon3 from '../images/smart-farm.png';
import Carousel from 'react-bootstrap/Carousel';
import carousel1 from '../images/container.png';
import carousel2 from '../images/gh1.png';
import carousel3 from '../images/indoor-farming.png';  
import image1 from '../images/in-garden.png';
import image2 from '../images/cocopeat-banner.png';
import image3 from '../images/degradasi-lahan.png';
import image4 from '../images/melon.png';
import image5 from '../images/fakta.png';
import image6 from '../images/tips.png';
import Buttonwa from '../components/ButtonWa';
import DocumentMeta from 'react-document-meta';
import tech from '../images/tech.png';
import background from  '../images/services-bg.png' ;

function FarmTechId() {

  const link = () => {  
    var url = "/farmhill-technology-en";
    return window.location.href = url;
  };

  const logo = () => {
    return <img src={tech}/>;
  };

  const meta = {
    title: 'Farmhill Technology',
    description: 'Praktik pertanian modern yang menawarkan pendekatan pertanian yang berkelanjutan dan inovatif. Dengan mengadopsi teknologi mutakhir seperti pertanian presisi dan AI, petani dapat meningkatkan hasil panen, mengurangi biaya, dan meminimalkan dampak lingkungan. Bergabunglah dengan revolusi pertanian modern dan jadilah bagian dari masa depan pertanian yang lebih efisien, menguntungkan, dan berkelanjutan.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'farmhill tech, farmhill, farmhill technology, farmhill semarang, IOT farmhill, modern farming, modern farming semarang, greenhouse semarang'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
    <div>
      <HeaderTech link={link} logo={logo}/>
      <Buttonwa/>
      <div class="blog_section">
         <div class="container">
            <div class="row">
               <div class="col-md-6 mt-5">
                  <h1 class="blog_taital">Technology for Future Farming</h1>
                  <p class="blog_text">Praktik pertanian modern yang menawarkan pendekatan pertanian yang berkelanjutan dan inovatif. Dengan mengadopsi teknologi mutakhir seperti pertanian presisi dan AI, petani dapat meningkatkan hasil panen, mengurangi biaya, dan meminimalkan dampak lingkungan. Bergabunglah dengan revolusi pertanian modern dan jadilah bagian dari masa depan pertanian yang lebih efisien, menguntungkan, dan berkelanjutan.</p>
               </div>
               <div class="col-md-6">
                  <div class="blog_img">
                    <img src={header} alt='./images/freepik.png'/>
                    </div>
               </div>
            </div>
         </div>
      </div>

      <div class="about-us section">
         <div class="container">
           <div class="row">
             <div class="col-lg-6">
               <div class="left-image">
                <img src={indoor} alt='./images/indoor-tech.jpg'/>
               </div>
             </div>
             <div class="col-lg-6 align-self-center">
               <div class="section-heading">
                 <h6 style={{fontWeight: "bold"}}>Tentang Kami</h6>
                 <h2 style={{fontWeight: "bold"}}>Growing Smarter, Not Harder: Modern Farming Solutions</h2>
               </div>
               <div class="row">
                 <div class="col-lg-4 col-sm-4">
                   <div class="about-item">
                     <h4>25</h4>
                     <h6>Lokasi Greenhouse</h6>
                   </div>
                 </div>
                 <div class="col-lg-4 col-sm-4">
                   <div class="about-item">
                     <h4>3</h4>
                     <h6>Unit Indoor Farming</h6>
                   </div>
                 </div>
                 <div class="col-lg-4 col-sm-4">
                   <div class="about-item">
                     <h4>IOT</h4>
                     <h6>specialist</h6>
                   </div>
                 </div>
               </div>
               <p p class="blog_text">Menekankan penggunaan teknologi canggih dan teknik inovatif dalam pertanian masa depan untuk meningkatkan efisiensi dan produktivitas.</p>
             </div>
           </div>
         </div>
       </div>
       
       <div class="services_section" style={{ backgroundImage: `url(${background})` }}>
         <div class="container">
            <h1 class="services_taital">Apa yang kami lakukan</h1>
            <p class="about_text">Pekerjaan kami untuk masa depan</p>
            <div class="services_section_2">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon1}/></div>
                     </div>
                     <h4 class="selection_text">Instalasi Greenhouse dan Hidroponik</h4>
                     <p class="ipsum_text">Rumah kaca berupa bangunan yang dirancang khusus untuk tanaman hidroponik dengan efektivitas penggunaan lahan yang tinggi.</p>  
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon2}/></div>
                     </div>
                     <h4 class="selection_text">Indoor Farming</h4>
                     <p class="ipsum_text">Teknologi pertanian dalam ruangan yang inovatif, dapat mengontrol kondisi lingkungan dengan presisi dan menumbuhkan tanaman yang segar dan berkualitas.</p>        
                  </div>
                  <div class="col-lg-4">
                     <div class="icon_box">
                        <div class="icon_1"><img src={icon3}/></div>
                     </div>
                     <h4 class="selection_text">AI & IOT Technology</h4>
                     <p class="ipsum_text">Meningkatkan efisiensi dan efektivitas pengolahan lahan dan penanaman dengan menggunakan teknologi yang lebih canggih.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="portfolio_section" style={{marginTop:'50px'}}>
      <h1 className='text-center'>Projek Kami</h1>
      <Carousel id="carousel">
        	
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel2}
          alt="First slide"
        />
        <Carousel.Caption>
          <h1>Greenhouse</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item"> 
        <img
          className="d-block w-100" 
          src={carousel3}
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Indoor Farming SMK N Moenadi</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} id="item">
        <img
          className="d-block w-100" 
          src={carousel1}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h3>Container Indoor Farming SMK N 6 Kendal</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>

    <div class="about_section" style={{marginTop:'30px'}}>
         <div class="container">
            <h1 className="article_taital">Artikel Kami</h1>
            <div class="about_section_2">
               <div class="row">
                <div class="col-lg-4">
                  <div className="article_image"><img src={image1}/></div>
                  <h4 class="selection_text">IN GARDEN : CONTAINER INDOOR FARMING</h4>
                  <p class="article_text">Perubahan iklim memiliki dampak signifikan terhadap pertanian. Peningkatan suhu global, pola curah hujan yang tidak teratur, ...</p>
                  <div class="read_bt_article"><a href="/in-garden-container-indoor-farming">Selengkapnya</a></div>
               </div>
               <div class="col-lg-4">
                <div class="article_image"><img src={image2}/></div>
                <h4 class="selection_text">COCOPEAT SEBAGAI SOLUSI DEGRADASI LAHAN DI INDONESIA</h4>
                <p class="article_text">Dalam bercocok tanam, tak hanya tanah yang bisa diandalkan sebagai media tanam. Masih ada media tanam lain, cocopeat salah ...</p>
                <div class="read_bt_article"><a href="/cocopeat-sebagai-solusi-degradasi-lahan-di-indonesia">Selengkapnya</a></div>
             </div>
             <div class="col-lg-4">
              <div class="article_image"><img src={image3}/></div>
              <h4 class="selection_text">ANCAMAN DEGRADASI TANAH BAGI PERTANIAN INDONESIA</h4>
              <p class="article_text">Degradasi lahan adalah proses penurunan produktivitas lahan, baik yang sifatnya ...</p>
              <div class="read_bt_article"><a href="/ancaman-degradasi-tanah-bagi-pertanian-indonesia">Selengkapnya</a></div>
           </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image4}/></div>
                     <h4 class="selection_text">BUDIDAYA MELON SISTEM DUTCH BUCKET</h4>
                     <p class="article_text">Sesuai namanya, sistem ini dipopulerkan oleh masyarakat Belanda yang terkenal efisien dalam manajemen air. Sistem Dutch Bucket ...</p>
                     <div class="read_bt_article"><a href="/budidaya-melon-sistem-dutch-bucket">Selengkapnya</a></div>
                  </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image5}/></div>
                     <h4 class="selection_text">FAKTA UNIK SISTEM IRIGASI TETES</h4>
                     <p class="article_text">Sistem yang paling banyak digunakan dalam budidaya melon hidroponik.
                        Sistem irigasi tetes dibagi menjadi 2 jenis yaitu sistem tertutup (dutch bucket) ...</p>
                     <div class="read_bt_article"><a href="/fakta-unik-sistem-irigasi-tetes">Selengkapnya</a></div>

                  </div>
                  <div class="col-lg-4">
                     <div class="article_image"><img src={image6}/></div>
                     <h4 class="selection_text">TIPS SUKSES BERHIDROPONIK</h4>
                     <p class="article_text">Ada beberapa tips sukses berhidroponik menurut Farmhill Technology antara lain :
                        Pilihlah jenis tanaman yang tepat (sesuai ...</p>
                     <div class="read_bt_article"><a href="/tips-sukses-berhidroponik">Selengkapnya</a></div>
                  </div>
                  
               </div>
            </div>
         </div>
      </div>
      <Footer/>
    </div>
    </DocumentMeta>
  );
}

export default FarmTechId;
